import { OrderedList, ListItem, Stack, Text, UnorderedList } from "@chakra-ui/react"

export const TermsPage = () => {
  return (
    <Stack p={20} h="100vh" overflowY="scroll">
      <Text fontSize="2xl" fontWeight="bold">
        Terms of Use
      </Text>
      <Text fontSize="md" pb={10}>
        Last Updated - May 2023
      </Text>
      <Text pb={10} fontSize="md">
        These Terms of Use, our Privacy Policy, any other terms, conditions, policies, and documents that may be incorporated into this document from time to time (collectively this “Agreement”) set forth the terms and conditions that apply to your access and use of the websites and software platforms owned or operated by ESO Management Services, LLC and its affiliated entities (collectively, the “Company”, “we”, “us”) and software or mobile applications related thereto and all information, content, tools, calculators, features and functionality located thereon (collectively, the “Service”) which are owned or provided by the Company, including but not limited to the site available at www.esofund.com.
      </Text>
      <OrderedList p={10}>
        <ListItem pb={5}>
          ACCEPTANCE. By registering, accessing, browsing, or otherwise the Service, you acknowledge that you have read and understood, and agree to be bound by the terms and conditions contained in this Agreement. You, the “User”, further represent and warrant that you are of legal age to use the Service and that you have the capacity to be bound by it or if you are acting on behalf of a company or entity that you have the authority to bind such entity.
        </ListItem>
        <ListItem pb={5}>
          INFORMATION FROM THIRD PARTIES AND USERS. The Service includes information, views, opinions and recommendations of individuals and organizations, including those of the Company, of interest to the investment community. The Company assumes no responsibility for the accuracy, legality, non-infringement, or fitness for any particular purpose or usefulness of information in any material available via the Service and it does not endorse or guarantee any such opinions or recommendations, nor does it give tax or investment advice or advocate or endorse the purchase or sale of any security or investment. Users should make an independent investigation before making any financial decisions. The Company is not responsible for any third-party products and services or third-party websites accessed through the Service.
        </ListItem>
        <ListItem pb={5}>
          CHANGES TO AGREEMENT.  We reserve the right, at our sole discretion, to change, modify, add, or remove portions of this Agreement at any time. You agree to check this Agreement periodically for changes. Your continued use of the Service after such changes have been posted constitutes your binding acceptance of such changes. Any changes to the Agreement will automatically be effective upon your continued use of the Service or on the date of the posting of such changes through the Service. The resolution of any dispute that arises between you and us will be governed by the Agreement in effect at the time such dispute arose.
        </ListItem>
        <ListItem pb={5}>
          THE SERVICE. The Service provider provides functionality for individuals to apply to exercise stock options or obtain liquidity for stock they hold in venture backed companies. Via the Service, you can submit an application that includes documents establishing your identity and information relate to your rights in the options or stock (collectively the “Application”) you are interested in monetizing. Upon receipt of the Application, and at our sole discretion, we may review the Application and determine whether to enter into a transaction with you. Submission of an Application or making an inquiry does not afford you any rights or given you any guarantee of entering a transaction with us. At our sole discretion, upon determining to engage, we may seek to enter certain agreements with you via the Service. During your use of any aspects of our Service, we may communicate with you via various electronic communication channels including email and text message, and you expressly consent to being contacted through those communication channels.
        </ListItem>
        <ListItem pb={5}>
          <Text fontSize="md">
            YOUR USE OF THE SERVICE. We reserve the right to monitor or remove any information transmitted or received through the Service, or to terminate your use of the Service, at any time, without notice, in its sole discretion. Your right to access and use the Service is personal to you and is not transferable by you to any other person or entity. You are only entitled to access and use the Service for lawful purposes.
          </Text>
          <UnorderedList p={5}>
            <ListItem pb={5}>
              USER INFORMATION.  Any information you provide to us in connection with the Service (“User Information”) must comply with this Agreement and all applicable law. You are solely responsible for all User Information you provide to us and represent that you have all necessary rights and hereby grant the Company a perpetual, worldwide, non-exclusive, royalty-free, sublicensable and transferable license to use, reproduce, distribute, prepare derivative works of, modify, display, and perform all or any portion of the User Information in connection with the Service, and the business of the Company. Any User Information provided to or data generated by your activities regarding the Service will be available to the Company is subject to our Privacy Policy and can be used for any of the purposes disclosed in that Privacy Policy.
            </ListItem>
            <OrderedList p={5}>
              <ListItem pb={5}>
                DATA USE RIGHTS. Notwithstanding anything herein to the contrary and in addition to all other rights granted hereunder, you hereby grant to the Company permission to generate anonymized and aggregate data (“Anonymized Data”) using the User Information, agree that the Company owns any such Anonymized Data, and hereby grant all right title and interest in and to the Anonymized Data to the Company to use for any purpose.  You understand that this allows the Company to share or publish the Anonymized Data and/or distribute or license such data to third parties. You also authorize us to use contact information you provide to make introductions to third parties that are related to the Service.
              </ListItem>
            </OrderedList>
            <ListItem pb={5}>
              CONFIDENTIALITY.  Any confidential information exchanged between you and the Company, including but not limited to any confidential User Information, shall be subject to the confidentiality terms of this Agreement.
            </ListItem>
            <ListItem pb={5}>
              USER ACCOUNT. We may provide you with a username and password (“Login Credentials”) to use to log in to your account on the Service. Your Login Credentials may not be shared with any other individuals and may not be used by multiple individuals. You are responsible for keeping your Login Credentials confidential and secure. You agree to immediately notify us of any disclosure, loss, or unauthorized use of any Login Credentials by emailing us at support@esofund.com. We are not liable for any loss or damage arising from your failure to protect your Login Credentials.
            </ListItem>
            <ListItem pb={5}>
              RESTRICTIONS ON USE. 
            </ListItem>
            <OrderedList p={5}>
              <ListItem pb={5}>
                You may not post or transmit any message, data, image or program that would violate the property rights of others, including unauthorized copyrighted text, images or programs, trade secrets or other confidential proprietary information, and trademarks or service marks used in an infringing fashion.
              </ListItem>
              <ListItem pb={5}>
                You may not interfere with other users' use of the Service, including, without limitation, taking any action that imposes a disproportionate burden on the Service infrastructure or that negatively affects the availability of the Service to others.
              </ListItem>
              <ListItem pb={5}>
                You may not use any robot, spider, scraper, deep link or other similar automated data gathering or extraction tools, program, algorithm or methodology to access, acquire, copy or monitor the Service, without the Company’s express written consent, which may be withheld in the Company’s sole discretion.
              </ListItem>
              <ListItem pb={5}>
                You may not use or attempt to use any engine, software, tool, agent, or other device or mechanism (including without limitation browsers, spiders, robots, avatars or intelligent agents) to navigate or search the Service, other than the search engines and search agents available through the Service and other than generally available third-party web browsers.
              </ListItem>
              <ListItem pb={5}>
                You may not post or transmit any file which contains viruses, worms, Trojan horses or any other contaminating or destructive features, or that otherwise interferes with the proper working of the Service. You may not attempt to decipher, decompile, disassemble, or reverse-engineer any of the software comprising or in any way making up a part of the Service.
              </ListItem>
              <ListItem pb={5}>
                To the extent you communicate with Company personnel or others in the Company community, engage in any vulgar, bullying, pornographic, offensive, threatening, libelous or defamatory, illegal, or otherwise inappropriate behavior, including by means of oral or written communications, images, or files.
              </ListItem>
              <ListItem pb={5}>
                If you are or are associated with a competitor of the Company, you may only use the Service for your personal benefit and may not use the Service (A) to gather competitive intelligence; (B) monitor the availability, performance, or functionality of the Service; or (C) for other benchmarking or any other competitive purposes.
              </ListItem>
            </OrderedList>
          </UnorderedList>
        </ListItem>
        <ListItem pb={5}>
          NO GUARANTEE OF ACCURACY OR RETENTION.  The Company does not guarantee any accuracy with respect to any information contained in any User Information. You are entirely responsible for all User Information that is uploaded, posted, transmitted, or otherwise made available by you through the Service, as well as for any actions reasonably taken by us based on that User Information.  
        </ListItem>
        <ListItem pb={5}>
          YOUR USER INFORMATION. Any information provided to or data generated by your activities regarding the Service will be available to the Company in order to assist you with the Services. To the extent you provide any registration information for use of the Service, you agree and understand that you are responsible for maintaining the confidentiality of any password or other login information which allows you to access the Service.
        </ListItem>
        <Text pb={5} fontSize="md">
          You agree and acknowledge that the Company is not a provider of data back-up, archiving, or data retention services. As between you and Company, you are solely and exclusively responsible for the backing up, archiving, and retaining of all User Information or any other of your information, data, or materials. While the Company adheres to reasonable policies and procedures intended to prevent the loss of User Information, including a daily system back-up regime, the Company does not make any representations, warranties or guarantees that the User Information or other of your information, data or materials will not be lost, altered, destroyed, damaged, or corrupted. WE HAVE NO OBLIGATION OR LIABILITY FOR ANY LOSS, ALTERATION, DESTRUCTION, DAMAGE, CORRUPTION, OR RECOVERY OF ANY USER INFORMATION.
        </Text>
        <ListItem pb={5}>
          TECHNICAL REQUIREMENTS.  You acknowledge and agree that the access to and use of the Service by you is dependent upon access to telecommunications and Internet services. You acknowledge that the Company is not responsible for acquiring or maintaining any telecommunications or Internet services or other hardware or software that you may need to access and use the Service, or for any costs, fees, expenses, or taxes of any kind related to the foregoing.
        </ListItem>
        <ListItem pb={5}>
          ILLEGAL USER INFORMATION. We are not obliged to pre-screen, monitor or filter any User Information for illegal content. However, if any User Information is determined to be illegal, or the act of processing the User Information is illegal in any way, and it is discovered or brought to our attention, or if we have any reason to believe that any User Information contains illegal content or is illegal to possess or process, we have the right to:
        </ListItem>
        <OrderedList p={5}>
          <ListItem pb={5}>
            Notify you of the illegal User Information;
          </ListItem>
          <ListItem pb={5}>
            Deny its processing by or publication on the Service;
          </ListItem>
          <ListItem pb={5}>
            Demand that you bring the illegal User Information into compliance with this Agreement or applicable law;
          </ListItem>
          <ListItem pb={5}>
            Temporarily or permanently remove the illegal User Information from the Service, restrict access to it or delete it with or without notice; and/or
          </ListItem>
          <ListItem pb={5}>
            Exercise any other rights under this Agreement including banning you from the Service.
          </ListItem>
        </OrderedList>
        <ListItem pb={5}>
          COPYRIGHT POLICY AND DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) PROCEDURES. We reserve the right to terminate your or any third party's right to use the Service if such use infringes the copyrights of another. We may, under appropriate circumstances and at our sole discretion, terminate your or any third party's right to access to the Service, if we determine that you or a third-party is a first time or repeat infringer. If you believe that any material has been posted via the Service by any third-party in a way that constitutes copyright infringement, and you would like to bring it to our attention, you must provide our DMCA Agent identified below with the following information: 
        </ListItem>
        <OrderedList p={5}>
          <ListItem pb={5}>
            An electronic or physical signature of the person authorized to act on behalf of the owner of the copyrighted work; 
          </ListItem>
          <ListItem pb={5}>
            An identification of the copyrighted work and the location on the Service of the allegedly infringing work;
          </ListItem>
          <ListItem pb={5}>
            A written statement that you have a good faith belief that the disputed use is not authorized by the owner, its agent or the law;
          </ListItem>
          <ListItem pb={5}>
            Your name and contact information, including telephone number and email address; and
          </ListItem>
          <ListItem pb={5}>
            A statement by you that the above information in your notice is accurate and, under penalty of perjury, that you are the copyright owner or authorized to act on the copyright owner's behalf.
          </ListItem>
        </OrderedList>
        <Text pb={5} fontSize="md">
          You may contact our DMCA Agent to send a notice of claims of copyright infringement at: support@esofund.com.
        </Text>
        <ListItem pb={5}>
          INTELLECTUAL PROPERTY RIGHTS. The “look and feel” (for example, the text, graphics, images, logos and button icons appearing on the websites or applications), photographs, editorial content, notices, software (including HTML-based computer programs) and other materials are protected under United States and other applicable copyright, trademark and other laws and belong to the Company or its software or content suppliers. The Company grants you the limited right to view and use the Service subject to this Agreement. Any distribution, reprint or electronic reproduction of any content from the Services, in whole or in part, for any other purpose is expressly prohibited without the Company’s prior written consent.  
        </ListItem>
        <ListItem pb={5}>
          CONFIDENTIALITY.
        </ListItem>
        <OrderedList p={5}>
          <ListItem pb={5}>
            DEFINITION OF CONFIDENTIAL INFORMATION. “Confidential Information” shall mean any information disclosed by either party (you or us, i.e., the “Disclosing Party”) to the other party (the “Receiving Party”, collectively the “Parties”), either directly or indirectly in writing, orally, or by inspection of tangible objects (a) that the Disclosing Party identifies as confidential or proprietary; or (b) that reasonably appears to be confidential or proprietary because of legends or other markings, the circumstances of disclosure, or the nature of the information itself. Your Confidential Information may include User Information and our Confidential Information includes the Service, all business or technical information relating thereto, any follow-on Agreements, letters, and/or pricing. Confidential Information does not include information that the Receiving Party can document:
          </ListItem> 
          <OrderedList p={5}>
            <ListItem pb={5}>
              has or becomes generally available to the public other than through a wrongful act of the Receiving Party; or     
            </ListItem>
            <ListItem pb={5}>
              was lawfully in its possession or known by them prior to receipt from the Disclosing Party; or 
            </ListItem>
            <ListItem pb={5}>
              was rightfully disclosed to them without restriction by a third party who is not bound by any confidentiality obligations with respect thereto; or
            </ListItem>
            <ListItem pb={5}>
              is independently developed by the Receiving Party, their employees or third-party contractors without use of or reference to the Confidential Information. 
            </ListItem>
            <Text pb={5} fontSize="md">
              For clarity, the non-disclosure obligations set forth in this “Confidentiality” section apply to Confidential Information exchanged between the Parties in connection with the Service or additional Company services and offerings.
            </Text>
          </OrderedList>
          <ListItem pb={5}>
            PROTECTION OF CONFIDENTIAL INFORMATION. All Confidential Information disclosed by a Disclosing Party shall remain the property of the Disclosing Party. The Disclosing Party reserves all rights in its Confidential Information. The Receiving Party will use the same degree of care that it uses to protect the confidentiality of its own confidential information of like kind (but not less than reasonable care) to:
          </ListItem>
          <OrderedList p={5}>
            <ListItem pb={5}>
              not use any Confidential Information of the Disclosing Party for any purpose outside the scope of this Agreement; and
            </ListItem>
            <ListItem pb={5}>
              except as otherwise authorized by the Disclosing Party in writing, limit access to Confidential Information of the Disclosing Party to those of its and its Affiliates’ employees and contractors who need that access for purposes consistent with this Agreement and who have signed confidentiality agreements with the Receiving Party containing protections not materially less protective of the Confidential Information than those herein.
            </ListItem>
          </OrderedList>
          <Text pb={5} fontSize="md">
            Notwithstanding the foregoing, we may disclose Confidential Information to a contractor to the extent necessary to perform our obligations under this Agreement, under terms of confidentiality materially as protective as set forth herein, or in association with a change of controls as provided for under this Agreement.
          </Text>
          <ListItem pb={5}>
            COMPELLED DISCLOSURE. Either Party may disclose Confidential Information to the extent required by law, provided that the Receiving Party gives the Disclosing Party reasonable advance notice of such required disclosure and cooperates with the Disclosing Party so that the Disclosing Party may obtain appropriate confidential treatment for such Confidential Information
          </ListItem>
        </OrderedList>
        <ListItem pb={5}>
          ASSIGNMENT.  You may not assign or transfer your rights or obligations under this Agreement to a third party without our prior written consent, and any assignment or transfer in derogation of the foregoing shall be null and void. We may freely assign and transfer our rights and obligations under this Agreement without your prior written consent. This includes an assignment or transfer by us to a successor entity in the event of merger, corporate reorganization or a sale of all or substantially all of our assets. This Agreement shall be binding upon the parties and their respective successors and permitted assigns.  
        </ListItem>
        <ListItem pb={5}>
          NOTICES. You may send any written notices required or permitted under this Agreement by email at support@esofund.com.
        </ListItem>
        <ListItem pb={5}>
          SERVICE AVAILABILITY.  It is possible that on occasion the Service will be unavailable due to maintenance or other development activity. If for any reason we have to interrupt the Service for longer periods than we would normally expect, we will use reasonable endeavors to publish in advance details of such activity on the Service, in email, or through another suitable channel.
        </ListItem>
        <ListItem pb={5}>
          GEOGRAPHIC RESTRICTIONS. The Company is based in the United States. We make no claims that the Service is accessible or appropriate outside of the United States. Access to and use of the Service may not be legal by certain persons or in certain countries. If you access the Service from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.
        </ListItem>
        <ListItem pb={5}>
          FORCE MAJEURE. We will not be liable for any delay or failure in the performance of our obligations under this Agreement if the delay or failure is due to any cause outside of our reasonable control.
        </ListItem>
        <ListItem pb={5}>
          DISCLAIMER OF REPRESENTATIONS AND WARRANTIES. THE CONTENT AND ALL SERVICES AND PRODUCTS ASSOCIATED WITH OR PROVIDED THROUGH THE SERVICE ARE PROVIDED TO YOU ON AN “AS-IS” AND “AS AVAILABLE” BASIS. THE COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE CONTENT OR OPERATION OF THE SERVICE. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE COMPANY MAKES NO REPRESENTATIONS, WARRANTIES OR GUARANTEES, EXPRESS OR IMPLIED, REGARDING THE ACCURACY, RELIABILITY OR COMPLETENESS OF THE CONTENT OF THE SERVICE, WHETHER SUCH CONTENT IS PROVIDED BY THE COMPANY, USERS OR BY THIRD PARTIES AND THE COMPANY EXPRESSLY DISCLAIMS ANY WARRANTIES OF NON-INFRINGEMENT OR FITNESS FOR A PARTICULAR PURPOSE. THE COMPANY MAKES NO REPRESENTATION, WARRANTY OR GUARANTEE THAT THE CONTENT THAT MAY BE AVAILABLE THROUGH THE SERVICE IS FREE OF INFECTION FROM ANY VIRUSES OR OTHER CODE OR COMPUTER PROGRAMMING ROUTINES THAT CONTAIN CONTAMINATING OR DESTRUCTIVE PROPERTIES OR THAT ARE INTENDED TO DAMAGE, SURREPTITOUSLY INTERCEPT OR EXPROPRIATE ANY SYSTEM, DATA OR PERSONAL INFORMATION.
        </ListItem>
        <ListItem pb={5}>
          LIMITATIONS ON LIABILITY AND INDEMNIFICATION. NONE OF THE COMPANY OR ITS AFFILIATES OR DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, CONSULTANTS OR MANAGERS (COLLECTIVELY, “AFFILIATES”) SHALL IN ANY EVENT BE RESPONSIBLE OR LIABLE TO YOU OR TO ANY THIRD PARTY, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, LIQUIDATED OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFIT, REVENUE OR BUSINESS, ARISING IN WHOLE OR IN PART FROM YOUR ACCESS TO OR YOUR USE OF THE SERVICE OR THIS AGREEMENT, EVEN IF THE COMPANY OR ITS AFFILIATES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. You AGREE TO defend, indemnify and hold harmless THE COMPANY and its affiliates from and against all claims and expenses, including but not limited to attorneys fees, in whole or in part arising out of or attributable to any breach of this Agreement by you.
        </ListItem>
        <ListItem pb={5}>
          TERMINATION. You agree that the Company may, in its sole discretion, terminate or suspend your access to all or part of the Service for any reason, including, without limitation, breach of this Agreement or any subsequent modifications, or attempted assignment of your account by you. The Company shall not be liable to you or any third party for the termination of Service or any claims related to the termination of Service. Notwithstanding anything to the contrary herein, all covenants, agreements, representations and warranties made by you in this Agreement including but not limited to limitations of liability and indemnification shall survive the termination of this Agreement.
        </ListItem>
        <ListItem pb={5}>
          GOVERNING LAW AND VENUE FOR DISPUTES. This Agreement shall be governed by and construed under the laws of the State of California as applied to agreements among California residents, made and to be performed entirely within the State of California, without giving effect to conflicts of laws principles. Any claim, dispute or controversy arising out of, or relating to, this Agreement, including any action or claim based on tort, contract or statute, including claims for equitable relief (permanent or preliminary) (“Claim”) shall be resolved by final and binding arbitration before the American Arbitration Association (“AAA”) in accordance with the AAA’s then existing rules and procedures. The arbitration shall be held in Palo Alto, California, and the final hearing, if any, shall be held no later than six months after the filing of the demand for arbitration. Discovery shall be limited to three depositions per party and ten document requests per party. Each party shall bear its own attorney’s fees, costs, and disbursements arising out of the arbitration, and shall pay an equal share of the fees and costs of the Administrator and the Arbitrators; provided, however, the Arbitrators shall be authorized to determine whether a party is substantially the prevailing party, and if so, shall award to that substantially prevailing party reimbursement for its reasonable attorneys’ fees, costs, disbursements and AAA fees and costs.
        </ListItem>
        <ListItem pb={5}>
          MISCELLANEOUS. If any portion of this Agreement is deemed unlawful, void, or unenforceable by any arbitrator or court of competent jurisdiction, this Agreement as a whole shall not be deemed unlawful, void, or unenforceable, but only that portion of this Agreement that is unlawful, void, or unenforceable shall be stricken from this Agreement. You agree that if the Company does not exercise or enforce any legal right or remedy which is contained in this Agreement (or which the Company has the benefit of under any applicable law), such failure to exercise such right or remedy may not be construed as a waiver of any other rights and remedies. This Agreement represents the entire understanding and agreement between you and the Company regarding the subject matter of the same, and supersedes all other previous agreements. If this Agreement or any future changes are unacceptable to you, your cessation of use of the Service is your exclusive remedy. The Company is required to provide no notices to you under this Agreement, but if it chooses to do so, it may send such notices to any email address you have provided or the Company may obtain that it believes are accurate.
        </ListItem>
      </OrderedList>
    </Stack>
  )
}