import { NavOption, OfferStatus } from "../types";

export const ALL_NAV_OPTIONS = [ //all nav options except for background check
  NavOption.CURRENT_OFFER,
  NavOption.TAX_CALCULATION,
  NavOption.RESOURCES,
  // NavOption.BACKGROUND_CHECK,
]

export const DEAL_MANAGER_NAV_OPTIONS = [
  NavOption.DASHBOARD
]

export const ALL_OFFER_STATUSES = [
  OfferStatus.INTRO,
  OfferStatus.ESO_REVIEW,
  OfferStatus.OFFER_READY,
  OfferStatus.PRE_CLOSING,
  OfferStatus.CONTRACT_REVIEW,
  OfferStatus.POST_CLOSING,
  OfferStatus.FULLY_FUNDED,
  OfferStatus.LIQUIDITY,
]

export const NavOptionDict = {
  account: "account",
  documents: "documents",
  current_offer: "offer",
  resources: "resources",
  tax_calculation: "tax",
  dashboard: "dashboard",
  client: "client",
  // background_check: "background"
}

export const IncentiveDeadlineDict = {
  two_years: "Two Years",
  one_year: "One Year",
  nine_months: "Nine Months",
  six_months: "Six Months",
  three_months: "Three Months"
}

export const DocumentTypeDict = {
  fmvProofDocument: "FMV",
  optionsVestingProofDocument: "Options_Vesting_Proof",
  optionPlanDocument: "Option_Plan",
  optionGrantDocument: "Option_Grant",
  stockCertificateDocument: "Stock_Certificate",
  driversLicenseDocument: "Drivers_License",
  passportDocument: "Passport",
  utilityBillDocument: "Utility_Bill"
}

export const WHITE_LISTED_FILE_TYPES = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword"
]

export const STATE_ABBREVIATIONS_MAP = {
  "Alabama": "AL",
  "Alaska": "AK",
  "Arizona": "AZ",
  "Arkansas": "AR",
  "California": "CA",
  "Colorado": "CO",
  "Connecticut": "CT",
  "Delaware": "DE",
  "Florida": "FL",
  "Georgia": "GA",
  "Hawaii": "HI",
  "Idaho": "ID",
  "Illinois": "IL",
  "Indiana": "IN",
  "Iowa": "IA",
  "Kansas": "KS",
  "Kentucky": "KY",
  "Louisiana": "LA",
  "Maine": "ME",
  "Maryland": "MD",
  "Massachusetts": "MA",
  "Michigan": "MI",
  "Minnesota": "MN",
  "Mississippi": "MS",
  "Missouri": "MO",
  "Montana": "MT",
  "Nebraska": "NE",
  "Nevada": "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  "Ohio": "OH",
  "Oklahoma": "OK",
  "Oregon": "OR",
  "Pennsylvania": "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  "Tennessee": "TN",
  "Texas": "TX",
  "Utah": "UT",
  "Vermont": "VT",
  "Virginia": "VA",
  "Washington": "WA",
  "West Virginia": "WV",
  "Wisconsin": "WI",
  "Wyoming": "WY"
}

export const STATES = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
]

export const ENVIRONMENT_LOCAL = "local"
export const ENVIRONMENT_PROD = "production"