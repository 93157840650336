export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export const format = (val: string) => `$` + val
export const parse = (val: string) => val.replace(/^\$/, '')

export const cleanseOfferStatusStr = (offerStatus: string): string => {
  let cleansedStr = offerStatus.replaceAll("_", " ")
  if (cleansedStr.includes("eso review")) {
    cleansedStr.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
    return cleansedStr.replace("eso review", "ESO Review")
  }
  return cleansedStr.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
}