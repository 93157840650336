import axios from "axios"
import { useEffect, useState } from "react"
import { Stack, useToast } from "@chakra-ui/react"
import { getStepByDealStage, DOCUMENT_TYPE_NDA, DOCUMENT_TYPE_BACKGROUND_CHECK } from "./constants"
import { ProgressBar, ToDoBox } from "./ui"
import { Deal, DealDocument, DealDocumentType,  } from "../../types"
import { getUser } from "../../hooks"

interface Props {
  deal: Deal
}

export const DealComponent = ({ deal }: Props) => {
  const toast = useToast()

  const [documents, setDocuments] = useState<DealDocument[]>([])

  useEffect(() => {
    const fetchDocumentsByDealId = async () => {
      const me = await getUser()
      const { didToken } = me
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/documents/${deal.id}`, {
        headers: { Authorization: `Bearer ${didToken}` }
      })

      const { documents } = data
      setDocuments(documents)
    }
    fetchDocumentsByDealId()
  }, [deal])

  const addDocument = async (key: string) => {
    const _documents = [...documents]
    _documents.push({ id: 0, type: key as DealDocumentType })
    setDocuments(_documents)
  }

  const requestForDocument = async (documentType: string) => {
    if (documentType === DOCUMENT_TYPE_NDA) {
      const response = await axios.post(`/documents/nda`, { dealId: deal.id })
      const { data } = response
      if (data && data.success) {
        toast({
          title: `NDA successfully sent to your email! If you don't see it, please contact your equity partner.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      } else {
        toast({
          title: `Oops something went wrong while requesting for an NDA. In the meantime, please email ${deal.dealManager.email} to request an NDA`,
          status: "error",
          duration: 3000,
          isClosable: true,
        })
      }
    } else if (documentType === DOCUMENT_TYPE_BACKGROUND_CHECK) {
      const response = await axios.post(`/documents/bc`, { dealId: deal.id })
      const { data } = response
      if (data && data.success) {
        toast({
          title: `Background Check successfully sent to your email! If you don't see it, please contact your equity partner.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      } else {
        toast({
          title: `Oops something went wrong while requesting for Background Check. In the meantime, please email ${deal.dealManager.email} to request a Background Check`,
          status: "error",
          duration: 3000,
          isClosable: true,
        })
      }
    }
  }

  return (
    <Stack pr={20} pl={20} pt={10} pb={10}>
      <ProgressBar currentStep={getStepByDealStage(deal)} totalSteps={4} />
      <ToDoBox deal={deal} documents={documents} addDocument={addDocument} requestForDocument={requestForDocument} />
    </Stack>
  )
}