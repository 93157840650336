import { AxiosInstance } from "axios"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { Box, HStack } from "@chakra-ui/react"
import { DealManagerPanel } from "../Main/ui"
import { NoDealComponent, OfferComponent } from "../Offer"
import { Client, Deal, UserType } from "../../types"
import { useUserContext } from "../../hooks"

interface Props {
  axios: AxiosInstance
}

const RightPanelContainer = styled(Box)``
RightPanelContainer.defaultProps = {
  width: '20%',
  height: '100%',
  position: 'fixed',
  right: 0,
  top: '0',
  overflowY: 'scroll',
  overflowX: 'hidden',
  background: '#8483ed',
}

const MainContainer = styled(Box)``
MainContainer.defaultProps = {
  width: '60%',
  height: '100%',
  position: 'fixed',
  top: 0,
  left: '20%',
  overflowY: 'scroll',
  overflowX: 'hidden',
  paddingBottom: "50px",
  background: "#f3f1f3"
}

export const DealComponent = ({ axios }: Props) => {
  const params = useParams()
  const user = useUserContext()
  const [client, setClient] = useState<Client | null>(null)
  const [deal, setDeal] = useState<Deal | null>(null)

  useEffect(() => {
    const fetchDeals = async () => {
      const { id } = params
      if (id) {
        const res = await axios.post(`/client/id`,  { clientId: parseInt(id) })
        const { data } = res
        if (data.success) {
          setClient(data.client)
          if (data.client.deals.length) {
            setDeal(data.client.deals[0])
          }
        }
      }
    }
    
    fetchDeals()

    if (user && user.type === UserType.DEAL_MANAGER) {
      fetchDeals()
    }
  }, [axios, params, user, setClient, setDeal])


  const getView = () => {
    if (client) {
      if (deal) {
        return (
          <HStack>
            <MainContainer>
              <OfferComponent axios={axios} stage={deal.stage} offers={deal.offers} company={deal.company} dealManager={deal.dealManager} />
            </MainContainer>
            <RightPanelContainer>
              <DealManagerPanel dealManager={deal.dealManager} />
            </RightPanelContainer>
          </HStack>
        )
      } else if (client.dealManager) {
        return (
          <HStack>
            <MainContainer>
              <NoDealComponent dealManager={client.dealManager} />
            </MainContainer>
            <RightPanelContainer>
              <DealManagerPanel dealManager={client.dealManager} />
            </RightPanelContainer>
          </HStack>
        )
      }
    }
  }

  return (
    <Box>
      { getView() }
    </Box>
  )
}