import { useState } from "react"
import { FaChevronDown, FaChevronRight } from "react-icons/fa"
import { IoIosCheckmarkCircle } from "react-icons/io";
import {
  Button,
  Divider,
  HStack,
  Icon,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react"
import { UploadFilesModal } from "../modals"
import { useDevice } from "../../../hooks"
import { Deal } from "../../../types"

interface Props {
  id: string
  deal: Deal
  instructions: string
  isUploaded: boolean
  name: string
  addDocument: (type: string) => void
}

export const UploadFilesRow = ({ id, deal, instructions, isUploaded, name, addDocument }: Props) => {
  const { isMobile } = useDevice()
  const [isOpen, setIsOpen] = useState(false)
  const [isUploadFilesModalOpen, setIsUploadFilesModalOpen] = useState(false)

  const handleUploadClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsUploadFilesModalOpen(true)
    event.stopPropagation()
  }

  return (
    <Stack pb={5} w="100%" h="100%" cursor="pointer" onClick={() => setIsOpen(!isOpen)}>
      <HStack>
        {
          !isMobile && (
            <Icon
              color="brand.300"
              as={isOpen ? FaChevronDown : FaChevronRight}
              w={5}
              h={5}
              pl={1}
              cursor="pointer"
              onClick={() => setIsOpen(!isOpen)}
            />
        )}
        <Text fontSize="lg">
          { name }
        </Text>
        {
          isUploaded && (
            <Icon as={IoIosCheckmarkCircle} h={4} w={4} color="green" />
          )
        }
        <Spacer />
        <Button bg="brand.300" color="white" onClick={handleUploadClick}>
          Upload
        </Button>
      </HStack>
      <Stack w="70%">
        {
          isOpen && (
            <Text fontSize="sm">
              { instructions }
            </Text>
          )
        }
      </Stack>
      <Divider />
      <UploadFilesModal
        deal={deal}
        id={id}
        isOpen={isUploadFilesModalOpen}
        addDocument={addDocument}
        onClose={() => setIsUploadFilesModalOpen(false)}
      />
    </Stack>
  )
}