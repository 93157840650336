import { useState } from "react"
import { FaChevronDown, FaChevronRight } from "react-icons/fa"
import {
  Button,
  Divider,
  HStack,
  Icon,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react"
import { useDevice } from "../../../hooks"

interface Props {
  id: string
  instructions: string
  name: string
  requestForDocument: (type: string) => void
}

export const RequestFormRow = ({ id, instructions, name, requestForDocument }: Props) => {
  const { isMobile } = useDevice()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Stack key={name} id={id} pb={5} w="100%" h="100%" cursor="pointer" onClick={() => setIsOpen(!isOpen)}>
      <HStack>
        {
          !isMobile && (
            <Icon
              color="brand.300"
              as={isOpen ? FaChevronDown : FaChevronRight}
              w={5}
              h={5}
              pl={1}
              cursor="pointer"
              onClick={() => setIsOpen(!isOpen)}
            />
        )}
        <Text fontSize="lg">
          { name }
        </Text>
        <Spacer />
        <Button bg="brand.300" color="white">
          Submit
        </Button>
      </HStack>
      <Stack w="70%">
        {
          isOpen && (
            <Text fontSize="sm">
              { instructions }
            </Text>
          )
        }
      </Stack>
      <Divider />
    </Stack>
  )
}