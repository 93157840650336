export type Maybe<T> = T | null

export type Scalars = {
  ID: number
  Boolean: boolean
  String: string
  Int: number
}

export enum UserType {
  CLIENT = "client",
  DEAL_MANAGER = "deal_manager",
}

export enum OfferStatus {
  INTRO = "intro",
  ESO_REVIEW = "eso_review",
  OFFER_READY = "offer_ready",
  PRE_CLOSING = "pre_closing",
  CONTRACT_REVIEW = "contract_review",
  POST_CLOSING = "post_closing",
  FULLY_FUNDED = "fully_funded",
  LIQUIDITY = "liquidity",
  CLOSED = "closed",
}

export type Client = {
  id: Maybe<Scalars['ID']>
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
  createdDate: Scalars['String']
  deals: Deal[]
  dealManager: Maybe<DealManager>
}

export type Company = {
  id: Scalars['ID']
  name: Scalars['String']
  fundingRounds: CompanyFundingRound[]
}

export type CompanyFundingRound = {
  price: Scalars['Int']
  date: Scalars['String']
  round: Scalars['String']
}

export type DealManager = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
  profilePicture: Scalars['String']
  phoneNumber: Scalars['String']
  calendly: Scalars['String']
}

export type Document = {
  name: Scalars['String']
  url: Scalars['String']
}

export type DocumentRow = {
  id: Scalars['String']
  instructions: Scalars['String']
  label: Scalars['String']
  description: Scalars['String']
  icon: Scalars['String']
  type: Scalars['String']
}

export type UploadDocumentRow = {
  id: Scalars['String']
  label: Scalars['String']
  description: Scalars['String']
  type: Scalars['String']
}

export enum EquityType {
  ISO = "iso",
  NSO = "nso",
  SHARE = "share",
  RSU = "rsu"
}

export enum StockOptionType {
  ISO = "iso",
  NSO = "nso",
}

export enum ResourceType {
  TAX = "tax",
  DOC = "documents",
}

export enum IncentiveType {
  TWO_YEARS = "two_years",
  ONE_YEAR = "one_year",
  NINE_MONTHS = "nine_months",
  SIX_MONTHS = "six_months",
  THREE_MONTHS = "three_months",
}

export enum NavOption {
  ACCOUNT = "account",
  DOCUMENTS = "documents",
  CURRENT_OFFER = "current_offer",
  RESOURCES = "resources",
  TAX_CALCULATION = "tax_calculation",
  DASHBOARD = "dashboard",
  CLIENT = "client",
  // BACKGROUND_CHECK = "background_check"
}

export type Equity = {
  name: Scalars['String']
  issueDate: Scalars['String']
  type: EquityType,
  amount: Scalars['Int']
  strikePrice: Scalars['Int']
  cost: Scalars['Int']
  tax: Scalars['Int']
  coveredEquityAmount: Scalars['Int']
}

export type TaxCalculation = {
  amount: Scalars['Int']
  strikePrice: Scalars['Int']
  type: StockOptionType,
}

export type Deal = {
  id: Scalars['ID']
  company: Company
  offers: Offer[]
  dealManager: DealManager
  stage: OfferStatus
  client: Client
  isActive: Scalars['Int']
}

export type DealDocument = {
  id: Scalars['ID']
  type: DealDocumentType
}

export enum DealDocumentType {
  _409A = "409A",
  FMV = "409A",
  OPTIONS_VESTING_PROOF = "Options_Vesting_Proof",
  OPTION_PLAN = "Option_Plan",
  OPTION_GRANT = "Option_Grant",
  STOCK_CERTIFICATE = "Stock_Certificate",
  DRIVERS_LICENSE = "Drivers_License",
  PASSPORT = "Passport",
  UTILITY_BILL = "Utility_Bill",
}

export type Offer = {
  exerciseCost: Maybe<Scalars['Int']>
  desiredLiquidity: Scalars['Int']
  pp: Scalars['Int']
  lp: Scalars['Int']
  isActive: Scalars['Int']
  isAccepted: Scalars['Int']
  isIncentive: Scalars['Int']
  incentivePp: Maybe<Scalars['Int']>
  incentiveLp: Maybe<Scalars['Int']>
  incentiveDeadline: Maybe<Scalars['String']>
  incentiveType: Maybe<IncentiveType>
  hypotheticalSharePrice: Scalars['Int']
  equities: Equity[]
  offerNumber: Scalars['Int']
}

export type Resource = {
  title: Scalars['String']
  url: Scalars['String']
  type: ResourceType
  photo: Maybe<Scalars['String']>
}

export type State = {
  name: Scalars['String']
}

export type TaxData = {
  year: Scalars['Int']
  marriageStatus: Scalars['String']
  bracket: Scalars['String']
  fedRate: Scalars['Int']
  fedLevel: Scalars['Int']
  fedOwe: Scalars['Int']
  fedDeduction: Scalars['Int']
  exemption: Scalars['Int']
  fedAmtLevel: Scalars['Int']
  amtThreshold: Scalars['Int']
  socSec: Scalars['Int']
  medicare: Scalars['Int']
}

export type StateTaxData = {
  year: Scalars['Int']
  state: Scalars['String']
  marriageStatus: Scalars['String']
  bracket: Scalars['String']
  stateRate: Scalars['Int']
  stateLevel: Scalars['Int']
  stateOwe: Scalars['Int']
  stateDeduction: Scalars['Int']
  stateAmtRate: Scalars['Int']
}

export type Me = {
  id: Scalars['ID']
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  createdDate: Maybe<Scalars['String']>
  type: UserType
}