import React, { useContext } from "react"
import Cookies from "js-cookie"

import { Me } from "../types"

export const removeSessionCookie = () => {
  Cookies.remove("me")
}

export const setSessionCookie = (user: Me, didToken: string) => {
  const _user = { ...user, didToken }
  Cookies.remove("me")
  Cookies.set("me", JSON.stringify(_user), { expires: 1/24 })
}

export const getSessionCookie: any = () => {
  const sessionCookie = Cookies.get("me")
  if (sessionCookie === undefined) {
    return null
  } else {
    return JSON.parse(sessionCookie) as Me
  }
}

export const getUser = async () => {
  const sessionCookie = Cookies.get("me")
  if (sessionCookie === undefined) {
    return null
  } else {
    return await JSON.parse(sessionCookie) as any
  }
}

export const getIsLoggedIn = () => {
  return Cookies.get("me") !== undefined
} 

export const UserContext = React.createContext<Me | null >(getSessionCookie())

export const useUserContext = () => {
  return useContext(UserContext)
}