import { useCallback, useState } from "react"
import { FaUpload } from "react-icons/fa"
import {
  Box,
  HStack,
  Spacer,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react"
import { DropZone, FileList, NegativeButton, PositiveButton } from "./"
import { WHITE_LISTED_FILE_TYPES } from "../../constants"
import { getUser } from "../../hooks"
import { Deal } from "../../types"

interface Props {
  deal: Deal
  documentKey: string
  addDocument: (key: string) => void
  onClose: () => void
}

export const UploadComponent = ({ deal, documentKey, addDocument, onClose }: Props) => {
  const toast = useToast()

  const [isLoading, setIsLoading] = useState(false)
  const [isDropActive, setIsDropActive] = useState(false)

  const [filesToUpload, setFilesToUpload] = useState<File[]>([])
  const onDragStateChange = useCallback((dragActive: boolean) => {
    setIsDropActive(dragActive)
  }, [])

  // Create handler for dropzone's onFilesDrop:
  // const onFilesDrop = useCallback((files: File[]) => {
  //   const _files = [...filesToUpload, ...files]
  //   console.log(filesToUpload)
  //   console.log(files)
  //   console.log(_files)
  //   setFilesToUpload(_files)
  // }, [filesToUpload])
  
  const onFilesDrop = (files: File[]) => {
    setFilesToUpload([...filesToUpload, ...files])
  }

  const clearFiles = () => {
    setFilesToUpload([])
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(filesToUpload)
    const files = event.target.files && event.target.files
    if (files) {
      const _files = [...filesToUpload, ...Array.from(files)]
      setFilesToUpload(_files)
    }
  }

  const makeSuccessToast = () => {
    toast({
      title: `Successfully uploaded your files!`,
      status: "success",
      duration: 3000,
      isClosable: true,
    })
  }

  const makeFailureToast = () => {
    toast({
      title: `Oops something went wrong while uploading your files. Please try again.`,
      status: "error",
      duration: 3000,
      isClosable: true,
    })
  }

  const uploadDocuments = async () => {
    setIsLoading(true)
    const me = await getUser()
    const { didToken } = me
    for (let i = 0; i < filesToUpload.length; i++) {
      const fileToUpload = filesToUpload[i]
      if (!WHITE_LISTED_FILE_TYPES.includes(fileToUpload.type)) {
        toast({
          title: `${fileToUpload.type} is not a valid file type`,
          status: "error",
          duration: 3000,
          isClosable: true,
        })
        return
      }
    }
    const formData = new FormData()
    const _key = documentKey === "FMV" ? "409A" : documentKey
    filesToUpload.forEach(file => formData.append("files", file))
    formData.append("key", String(_key))
    formData.append("dealId", deal.id.toString())
    const res = await fetch(`${process.env.REACT_APP_SERVER_URL!}/documents/upload/bulk`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${didToken}`,
      },
      credentials: "include"
    })
    
    const uploadResponse = await res.json()
    setIsLoading(false)
    clearFiles()
    const { success } = uploadResponse
    if (success) {
      makeSuccessToast()
      addDocument(documentKey)
      onClose()
    } else {
      makeFailureToast()
    }
  }

  return (
    <Box pl={10} pr={10} borderRadius={10}>
      <VStack spacing={10} pt={10} overflowY="scroll">
        <Box w="100%" borderRadius={10}>
          <DropZone onDragStateChange={onDragStateChange} onFilesDrop={onFilesDrop}>
            <VStack pt={10} pb={10}>
              <FaUpload size={50}  />
              <Box className="file-upload-container" mt={5} mb={5}>
                <label className="file-upload-label">
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                    className="file-upload-input"
                    onChange={handleFileChange}
                    multiple
                  />
                  Choose a file
                </label>
              </Box>
              { filesToUpload.length === 0 ? (
                <Text fontSize="sm">No files to upload</Text>
              ) : (
                <Text>Files to upload: {filesToUpload.length}</Text>
              )}

              {/* Render the file list */}
              <FileList files={filesToUpload} />
              {
                filesToUpload.length > 0 && (
                  <HStack pt={5}>
                    <PositiveButton onClick={uploadDocuments} isLoading={isLoading}>Upload</PositiveButton>
                    <NegativeButton onClick={clearFiles}>Clear</NegativeButton>
                  </HStack>
                )
              }
              {
                isLoading && (
                  <Text>Uploading your files... Please don't change pages.</Text>
                )
              }
            </VStack>
          </DropZone>
        </Box>
        <Spacer p={20}/>
      </VStack>
    </Box>
  )
}
