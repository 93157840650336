import { VStack } from "@chakra-ui/react";
import React from "react"

interface ProgressBarProps {
  totalSteps : number;
  currentStep: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ totalSteps , currentStep }) => {
  const steps = Array.from({ length: totalSteps }, (_, index) => index + 1);
  const progressPercentage = ((currentStep - 1) / (totalSteps - 1)) * 100;
  const stepLabels = ["Offer", "Contract", "Funding", "Liquidity"]

  return (
    <div className="progress-bar-container">
      <div className="progress-line-background" />
      <div
        className="progress-line-foreground"
        style={{ width: `${progressPercentage}%` }}
      />
      <div className="circles-container">
        {steps.map((step, index) => (
          <VStack key={step}>
            <div className={`circle ${step <= currentStep ? 'completed' : ''}`}>
              {step < currentStep ? '✔' : step}
            </div>
            <div className="circle-label">{stepLabels[index]}</div>
          </VStack>
        ))}
      </div>
    </div>
  )
}