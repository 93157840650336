import { AxiosInstance } from "axios"
import { useEffect, useState } from "react"
import { Box, Grid, Text, VStack } from "@chakra-ui/react"
import { Me, Resource } from "../../types"
import { ResourceGridItem } from "./ui"
import { CopyrightFooter, EquityPartnerNavigationBar, NavigationBar } from "../Main/ui"
import { getUser } from "../../hooks"
import { UserType, } from "../../types"
import { useNavigate } from "react-router-dom"

interface Props {
  axios: AxiosInstance
}

export const ResourcesPage = ({ axios }: Props) => {
  const navigate = useNavigate()
  const [resources, setResources] = useState<Resource[]>([])
  const [user, setUser] = useState<Me | null>(null)
  
  useEffect(() => {
    const fetchResources = async () => {
      const response = await axios.post(`/resources/all`)
      const { data } = response
      if (data && data.success) {
        const _resources: Resource[] = data.resources
        setResources(_resources)
      }
    }
    const fetchUser = async () => {
        const user = await getUser()
        setUser(user)
    }
    fetchUser()
    fetchResources()
  }, [])

  const getNav = () => {
    if(user){
      if (user.type === UserType.DEAL_MANAGER) {
        return <EquityPartnerNavigationBar />
      } else if (user.type === UserType.CLIENT) {
        return <NavigationBar />
      }
    }
    else{
      navigate(`/login`)
    }
  } 
  
  
  return (
    <VStack h="100%">
      { getNav() }
      <VStack h="100vh" overflowY="scroll" p={10}>
        <Text fontSize="2xl" fontWeight="bold">
          Helpful Resources
        </Text>
        <Grid templateColumns='repeat(3, 1fr)' gap={6} p={10}>
          {
            resources.map((resource, index) => {
              return (
                <ResourceGridItem key={index} resource={resource} />
              )
            })
          }
        </Grid>
        <Box p={20}>
          <CopyrightFooter />
        </Box>
      </VStack>
    </VStack>
  )
}
