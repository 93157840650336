import { useNavigate } from "react-router-dom"
import { Avatar, Box, Divider, HStack, Spacer, Stack, Text } from "@chakra-ui/react"
import { Deal } from "../../../types"
import { cleanseOfferStatusStr } from "../../../utils"

interface Props {
  deal: Deal
}

export const DealRow = ({ deal }: Props) => {
  const navigate = useNavigate()

  const handleDealRowClick = () => {
    if (deal.isActive === 1) {
      navigate(`/deal/${deal.id}/offer`)
    }
  }

  return (
    <Box
      borderRadius={10}
      border="1px"
      borderColor="gray.300"
      cursor={deal.isActive === 1 ? "pointer" : "default"}
      onClick={handleDealRowClick}
    >
      <Text
        fontSize="2xl"
        fontWeight="bold"
        color="black"
        pt={5}
        pl={5}
        pr={5}
      >
        { deal.company.name }
      </Text>
      {
        deal.isActive === 0 ? (
          <HStack p={5}>
            <Text
              bg="red.200"
              borderRadius={10}
              fontSize="sm"
              fontWeight="bold"
              color="red.500"
              pl={2}
              pr={2}
            >
              Inactive
            </Text>
            <Spacer />
          </HStack>
        ) : (
          <Text
            fontSize={16}
            color="gray.600"
            p={5}
          >
            { cleanseOfferStatusStr(deal.stage) }
          </Text>
        )
      }
      <Divider />
      <Text
        fontSize={16}
        color="gray.600"
        pl={5}
        pt={5}
        pr={5}
      >
        Equity Partner:
      </Text>
      <HStack p={5}>
        <Avatar name={`${deal.dealManager.firstName} ${deal.dealManager.lastName}`} w={20} h={20} src={deal.dealManager.profilePicture} />
        <Stack>
          <Text
            fontSize={16}
            color="gray.600"
            pl={5}
          >
            { `${deal.dealManager.firstName} ${deal.dealManager.lastName}` }
          </Text>
          <Text
            fontSize={16}
            color="gray.600"
            pl={5}
          >
            { deal.dealManager.phoneNumber }
          </Text>
          <Text
            fontSize={16}
            color="gray.600"
            pl={5}
          >
            { deal.dealManager.email }
          </Text>
        </Stack>
      </HStack>
    </Box>
  )
}
