import React, { createContext, useContext, ReactNode } from 'react';

interface AuthContextProps {
  didToken: string | null;
  setDidToken: (token: string | null) => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [didToken, setDidToken] = React.useState<string | null>(null);

  return (
    <AuthContext.Provider value={{ didToken, setDidToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};