import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react"

interface Props {
  value: string | number
  setValue: (value: string) => void
}

export const DollarInput = ({ value, setValue }: Props) => {
  return (
    <NumberInput
      defaultValue={value}
      precision={2}
      step={0.01}
      onChange={(value) => setValue(value)}
    >
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  )
}
