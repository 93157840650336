import {
  Stack,
  Text,
} from "@chakra-ui/react"

interface Props {
  label: string,
  value: string,
}

export const TaxResultBox = ({ label, value }: Props) => {
  return (
    <Stack p={5} bg="white" borderRadius={10}>
      <Text fontSize={12} color="black">
        { label }
      </Text>
      <Text fontSize={20} color="black" fontWeight="extrabold">
        { value }
      </Text>
    </Stack>
  )
}
