import { Stack, Text } from "@chakra-ui/react"

interface Props {
  files: File[]
}

export const FileList = ({ files }: Props) => {
  
  return (
    <Stack pt={5}>
      {
        files.map((file, index) => (
          <Text key={index}>{file.name}</Text>
        ))
      }
    </Stack>
  )
}
