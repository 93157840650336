import { useNavigate } from "react-router-dom"
import { HStack, Link, Text, VStack } from "@chakra-ui/react"

export const CopyrightFooter = () => {
  const navigate = useNavigate()

  return (
    <VStack pt={200}>
      <Text fontSize="sm" color="gray">
        © Copyright 2023, ESO Fund, LLC. All rights reserved.
      </Text>
      <HStack>
        <Link fontSize="sm" color="brand.100" onClick={() => navigate(`/privacy-policy`)}>
          Privacy Policy
        </Link>
        <Link fontSize="sm" color="brand.100" onClick={() => navigate(`/terms`)}>
          Terms of Service
        </Link>
      </HStack>
    </VStack>
  )
}