import { Box, Link, Text, Stack, HStack, Spacer } from "@chakra-ui/react"
import { Deal } from "../../../types"
import { cleanseOfferStatusStr } from "../../../utils"

interface NavigationItemProps {
  deal: Deal
  isCurrentNavOption: boolean
  setDeal: (deal: Deal) => void
}

const NavigationItem = ({ deal, isCurrentNavOption, setDeal }: NavigationItemProps) => {
  return (
    <HStack
      p={ isCurrentNavOption ? 4 : 2}
      borderRadius={10}
      bg={ isCurrentNavOption ? "brand.300" : "transparent" }
      cursor="pointer"
      onClick={() => setDeal(deal)}
    >
      <Text
        as={Link}
        fontSize="sm"
        color={ isCurrentNavOption ? "white" : "#989eb2" }
        fontWeight={ isCurrentNavOption ? "bold" : "medium" }
      >
        { deal.company.name }
      </Text>
      <Spacer />
      <Text
        as={Link}
        fontSize="xs"
        color={ isCurrentNavOption ? "white" : "#989eb2" }
      >
        { cleanseOfferStatusStr(deal.stage) }
      </Text>
    </HStack>
  )
}

interface Props {
  deals: Deal[]
  currentDeal: Deal | null
  setDeal: (deal: Deal) => void
}

export const Navigation = ({ deals, currentDeal, setDeal }: Props) => {
  return (
    <Stack pt={5}>
      {
        deals.map(deal => {
          const isCurrentNavOption = currentDeal ? deal.id === currentDeal.id : false
          return (
            <Box key={deal.id} pl={5} pr={5}>
              <NavigationItem
                isCurrentNavOption={isCurrentNavOption}
                deal={deal}
                setDeal={setDeal}
              />
            </Box>
          )
        })
      }
    </Stack>
  )
}
