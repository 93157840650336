import { IoMdClose } from "react-icons/io"
import {
  Box,
  HStack,
  IconButton,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react"
import { DollarInput } from "../../Main/ui/DollarInput"
import { StockOptionType, TaxCalculation } from "../../../types"
import { format, parse } from "../../../utils"
import { useDevice } from "../../../hooks"

interface Props {
  index: number
  taxCalculation: TaxCalculation
  isDeletable: boolean
  setAmount: (index: number, amount: number) => void
  setStrikePrice: (index: number, strikePrice: number) => void
  setStockOptionType: (index: number, type: string) => void
  deleteTaxCalculation: (index: number) => void
}

export const TaxCalculationRow = ({
  index,
  taxCalculation,
  isDeletable,
  setAmount,
  setStrikePrice,
  setStockOptionType,
  deleteTaxCalculation
}: Props) => {

  const { isMobile } = useDevice()

  const handleAmountChange = (value: string) => {
    setAmount(index, parseInt(value))
  }

  const handleStrikePriceChange = (value: string) => {
    setStrikePrice(index, parseFloat(parse(value)))
  } 

  return (
    <>
    {
      isMobile ? (
        <Stack spacing={0} key={index} m={0}>
          <Stack borderRadius={10} bg="white" p={3}>
            <Text fontSize={15} textAlign="left">
              Amount Vested
            </Text>
            <DollarInput value={taxCalculation.amount} setValue={handleAmountChange} />
          </Stack>
          <Stack borderRadius={10} bg="white" p={3}>
            <Text fontSize={15} textAlign="left">
              Stock Option Type
            </Text>
            <Select onChange={(event) => setStockOptionType(index, event.target.value)}>
              <option value={StockOptionType.ISO}>ISO</option>
              <option value={StockOptionType.NSO}>NSO</option>
            </Select>
          </Stack>
          <Stack borderRadius={10} bg="white" p={3}>
            <Text fontSize={15} textAlign="left">
              Strike Price
            </Text>
            <DollarInput value={format(String(taxCalculation.strikePrice))} setValue={handleStrikePriceChange} />
          </Stack>
          {
            isDeletable && (
              <Stack>
                <Box h={25} />
                <IconButton
                  variant='outline'
                  colorScheme='brand'
                  aria-label='Delete Grant'
                  icon={<IoMdClose />}
                  onClick={() => deleteTaxCalculation(index)}
                />
              </Stack>
            )
          }
        </Stack>
      ) : (
        <HStack spacing={5} key={index}>
          <Stack w="30%" borderRadius={10} bg="white" p={5}>
            <Text fontSize={15} textAlign="left">
              Amount Vested
            </Text>
            <DollarInput value={taxCalculation.amount} setValue={handleAmountChange} />
          </Stack>
          <Stack w="30%" borderRadius={10} bg="white" p={5}>
            <Text fontSize={15} textAlign="left">
              Stock Option Type
            </Text>
            <Select onChange={(event) => setStockOptionType(index, event.target.value)}>
              <option value={StockOptionType.ISO}>ISO</option>
              <option value={StockOptionType.NSO}>NSO</option>
            </Select>
          </Stack>
          <Stack w="30%" borderRadius={10} bg="white" p={5}>
            <Text fontSize={15} textAlign="left">
              Strike Price
            </Text>
            <DollarInput value={format(String(taxCalculation.strikePrice))} setValue={handleStrikePriceChange} />
          </Stack>
          {
            isDeletable && (
              <Stack>
                <Box h={25} />
                <IconButton
                  variant='outline'
                  colorScheme='brand'
                  aria-label='Delete Grant'
                  icon={<IoMdClose />}
                  onClick={() => deleteTaxCalculation(index)}
                />
              </Stack>
            )
          }
        </HStack>
      )
    }
    </>
    
  )
}
