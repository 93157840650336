import axios from "axios"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import {
  Client,
  Company,
  Deal,
  DealManager,
  NavOption,
  Resource,
} from "../../types"
import { Box, HStack, Spacer, Spinner, Stack, Text, VStack } from "@chakra-ui/react"
import { CopyrightFooter, DealManagerPanel, Navigation, NavigationBar, ReturnToPortfolioLink } from "./ui"
import { NoDealComponent } from "../Offer"
import { TaxCalculationComponent } from "../Tax"
import { ResourcesComponent } from "../Resources"
import { getIsLoggedIn, useDevice, getUser, useClientContext } from "../../hooks"
import { DealComponent } from "../Deal"

const LeftPanelContainer = styled(Stack)``
LeftPanelContainer.defaultProps = {
  width: 'calc(100% / 5)',
  height: 'calc(100%)',
  position: 'fixed',
  left: 0,
  // top: "60px",
  paddingTop: "20px",
  // background: "#5856e7",
  background: '#1F2838',
  overflowY: 'scroll',
  overflowX: 'hidden',
  borderRight: "1px solid #edebeb"
}

const LargeContainer = styled(Box)``
LargeContainer.defaultProps = {
  width: '80%',
  height: '100%',
  position: 'fixed',
  left: '20%',
  overflowY: 'scroll',
  overflowX: 'hidden',
  paddingBottom: "50px",
  background: "white"
}

const MobileContainer = styled(Box)``
MobileContainer.defaultProps = {
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: "60px",
  left: '0',
  overflowY: 'scroll',
  overflowX: 'hidden',
  paddingBottom: "50px",
  background: "white"
}

interface Props {
  currentNavOption: string
}

export const DashboardPage = ({ currentNavOption }: Props) => {
  const params = useParams()
  const { id } = params
  const {isMobile} = useDevice()
  const [deal, setDeal] = useState<Deal | null>(null)
  const [client, setClient] = useState<Client | null>(null)
  const [dealManager, setDealManager] = useState<DealManager | null>(null)
  const [resources, setResources] = useState<Resource[]>([])
  
  useEffect(() => {
    const fetchClient = async () => {
      try {
        const me = await getUser()
        const { didToken } = me
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/client/`, {}, {
          headers: { Authorization: `Bearer ${didToken}` }
        })
        const { data } = response
        if (response.status === 200 && data?.success) {
          const _client: Client = data.client
          console.log(_client)
          setClient(_client)
          const { deals, dealManager } = _client
          if (deals.length > 0) {
            setDeal(deals[0])
          }

          if (dealManager) {
            setDealManager(dealManager)
          }
        } else {
          // navigate(`/login`)
        }
      } catch(e) {
        // navigate(`/login`)
      }
    }

    const isLoggedIn = getIsLoggedIn()

    if (isLoggedIn) {
      fetchClient()
    }
  }, [])

  const getMainContainerComponent = () => {
    if (currentNavOption === NavOption.CURRENT_OFFER) {
      if (deal) {
        return (
          <DealComponent deal={deal} />
        )
      } else {
        if (dealManager) {
          return (
            <NoDealComponent
              dealManager={dealManager}
            />
          )
        }
      }
    } else if (currentNavOption === NavOption.TAX_CALCULATION) {
      return (
        <TaxCalculationComponent />
      )
    } else if (currentNavOption === NavOption.RESOURCES) {
      return (
        <ResourcesComponent resources={resources} />
      )
    }
  }

  
  return (
    <>
      {
        isMobile ? (
          <>
            <NavigationBar dealManager={dealManager} />
            <Stack h='100vh'>
              <MobileContainer>
                { getMainContainerComponent() }
                <Box p={20}>
                  <CopyrightFooter />
                </Box>
              </MobileContainer>
            </Stack>
          </>
        ) : (
          <>
            <LeftPanelContainer>
              <HStack pl={5} pr={5}>
                <Text fontSize="sm" fontWeight="extrabold" color="white">
                  Your Companies:
                </Text>
                <Spacer />
              </HStack>
              {
                client?.deals ? (
                  <Navigation deals={client?.deals ?? []} currentDeal={deal} setDeal={setDeal} />
                ) : (
                  <VStack h="100%">
                    <Spacer />
                    <Spinner color="white" />
                    <Spacer />
                  </VStack>
                )
              }
              <Spacer />
              {
                dealManager && (
                  <DealManagerPanel dealManager={dealManager} />
                )
              }
            </LeftPanelContainer>
            <Stack h="100%">
              <LargeContainer>
                {
                  deal ? (
                    <Text p={5} w="100%" fontSize="2xl" fontWeight="bold" color="white" bg="brand.300">
                      { `${deal.client.firstName}'s ${deal.company.name} Equity` }
                    </Text>
                  ) : (
                    <VStack h="100%">
                      <Spacer />
                      <Spinner />
                      <Spacer />
                    </VStack>
                  )
                }
                { getMainContainerComponent() }
                <Box p={20}>
                  <CopyrightFooter />
                </Box>
              </LargeContainer>
            </Stack>
          </>
        )
      }
    </>
  )
}
