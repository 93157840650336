import { Avatar, Button, HStack, Spacer, Stack, Text, VStack } from "@chakra-ui/react"
import { DealManager } from "../../../types"
import { openInNewTab } from "../../../utils"

interface Props {
  dealManager: DealManager
}

export const DealManagerPanel = ({ dealManager }: Props) => {
  const { firstName, lastName, email, phoneNumber, profilePicture } = dealManager
  
  return (
    <VStack pt={10} pb={5}>
      <HStack w="100%">
        <Text
          fontSize="md"
          color="white"
          p={2}
          pl={10}
        >
          Equity Partner:
        </Text>
        <Spacer />
      </HStack>
      <HStack spacing={5} pb={5}>
        <Avatar name={`${firstName} ${lastName}`} w={50} h={50} src={profilePicture} />
        <Stack spacing={0}>
          <Text fontSize="sm" color="white">
            { `${firstName} ${lastName}` }
          </Text>
          <Text fontSize="sm" color="white">{ email }</Text>
          <Text fontSize="sm" color="white">{ phoneNumber }</Text>
        </Stack>
      </HStack>
      <Button
        bg="brand.300"
        color="white"
        mb={10}
        onClick={() => openInNewTab(dealManager.calendly)}
      >
        Schedule a call
      </Button>
    </VStack>
  )
}
