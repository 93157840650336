import { AxiosInstance } from "axios"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import {
  Box,
  HStack,
  Link,
  Spacer,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react"
import { EquityPartnerNavigationBar, EquityPartnerDashboardSearchBar, Navigation} from "./ui"
import { DealsComponent } from "../Deals"
import { getIsLoggedIn, getUser } from "../../hooks"
import { NavOption, Deal, UserType, } from "../../types"
import { DealManagerDealComponent } from "../Deal"

const LeftPanelContainer = styled(Stack)``
LeftPanelContainer.defaultProps = {
  width: 'calc(100% / 5)',
  height: 'calc(100%)',
  position: 'fixed',
  left: 0,
  top: "60px",
  paddingTop: "20px",
  // background: "#5856e7",
  background: '#1F2838',
  overflowY: 'scroll',
  overflowX: 'hidden',
  borderRight: "1px solid #edebeb"
}

const LargeContainer = styled(Box)``
LargeContainer.defaultProps = {
  top: "60px",
  width: '80%',
  height: '100%',
  position: 'fixed',
  left: '20%',
  overflowY: 'scroll',
  overflowX: 'hidden',
  paddingBottom: "50px",
  background: "white"
}

const MobileContainer = styled(Box)``
MobileContainer.defaultProps = {
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: "60px",
  left: '0',
  overflowY: 'scroll',
  overflowX: 'hidden',
  paddingBottom: "50px",
  background: "white"
}

interface Props {
  currentNavOption: string
  axios: AxiosInstance
}

export const EquityPartnerDashboardPage = ({ currentNavOption, axios }: Props) => {
  const navigate = useNavigate()
  const params = useParams()
  
  const [deal, setDeal] = useState<Deal | null>(null)
  const [deals, setDeals] = useState<Deal[]>([])
  const [clientDeals, setClientDeals] = useState<Deal[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const isLoggedIn = getIsLoggedIn()
    const { id } = params

    if (isLoggedIn) {
      if(NavOption.CLIENT === currentNavOption && id) {
        fetchDealbyId(id)
      }
    }
  }, [currentNavOption, params])

  
  //fetch deal for offer page
  const fetchDealbyId = async (id: string) => {
    try {
      const user = await getUser()
      if (user && user.type === UserType.DEAL_MANAGER) {
        const { didToken } = user
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/deal-manager/deal/${id}`, {
          headers: { Authorization: `Bearer ${didToken}` }
        })
        const { data } = response
        if (response.status === 200 && data?.success) {
          await fetchDealsByClientId(data.deal.client.id, didToken)
          setDeal(data.deal)
        }
      } else {
        navigate(`/login`)
      }
    } catch(e) {
      console.log(e)
      navigate(`/login`)
    }
  }

  const fetchDealsByClientId = async (clientId: number, token: string) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/deal-manager/client/deals/${clientId}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      const { data } = response
      if (response.status === 200 && data?.success) {
        console.log(data.deals)
        setClientDeals(data.deals)
      }
    } catch(e) {
      console.log(e)
    }
  }

  const searchDealsBySearchText = async (searchText: string) => {
    setIsLoading(true)
    try {
      const user = await getUser()
      if (user && user.type === UserType.DEAL_MANAGER) {
        const { didToken } = user
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/deal-manager/deals/search/${searchText}`, {
          headers: { Authorization: `Bearer ${didToken}` }
        })
        setIsLoading(false)
        const { data } = response
        if (response.status === 200 && data?.success) {
          setDeals(data.deals)
        }
      } else {
        navigate(`/login`)
      }
    } catch(e) {
      console.log(e)
      setIsLoading(false)
      navigate(`/login`)
    }
  }

  
  const handleReturnToHomepageClick = () => {
    setDeal(null)
    setClientDeals([])
    navigate("/dashboard")
  }

  const getComponent = () => {
    if (currentNavOption === NavOption.CLIENT) {
      if (deal) {
        return (
          <>
            <LeftPanelContainer>
              <HStack pl={5} pr={5}>
                <Text fontSize="sm" fontWeight="extrabold" color="white">
                  Your Companies:
                </Text>
                <Spacer />
              </HStack>
              {
                deal.client && (
                  <Navigation deals={clientDeals} currentDeal={deal} setDeal={setDeal} />   
                )
              }
              <Spacer />
            </LeftPanelContainer>
            <Stack h="100%" pb={10}>
              <LargeContainer>
                {
                  deal && (
                    <HStack w="100%">
                      <Text p={5} fontSize="md" fontWeight="bold" color="black">
                        { `${deal.client.firstName}'s ${deal.company.name} Equity` }
                      </Text>
                      <Spacer />
                      <Text as={Link} pr={5} fontSize="sm" onClick={handleReturnToHomepageClick}>
                        Return to homepage
                      </Text>
                    </HStack>
                  )
                }
                <DealManagerDealComponent deal={deal} />
              </LargeContainer>
              <Spacer />
            </Stack>
          </>
        )
      }
    } else if (currentNavOption === NavOption.DASHBOARD) {
      return (
        <Stack h="100%" pb={10}>
          <EquityPartnerDashboardSearchBar searchDeals={searchDealsBySearchText} />
          {
            isLoading ? (
              <VStack h="100%">
                <Spacer />
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                  size='xl'
                />
                <Spacer />
              </VStack>
            ) : (
              <DealsComponent deals={deals} />
            )
          }
        </Stack>
      )
    }
  }

  return (
    <VStack bg="#F6F6FB" h="100vh">
      <EquityPartnerNavigationBar />
      <Stack h="100%" mb={20} w="70%" overflowY="scroll">
        { getComponent() }
      </Stack>
    </VStack> 
  )
}