import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { useEffect, useState } from "react"
import { Line } from "react-chartjs-2"
import { Box, Stack } from "@chakra-ui/react"
import { Company, CompanyFundingRound, Offer } from "../../../types"
import { useDevice } from "../../../hooks"
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Total Return vs Exit Share Price',
    },
  },
}

interface Props {
  company: Company
  offer: Offer
  pp: number
  lp: number
}

export const OfferChart = ({ company, offer, pp, lp }: Props) => {
  const { isMobile } = useDevice()
  const getLatestFundingRound = (fundingRounds: CompanyFundingRound[]) => {
    if (!fundingRounds) {
      return null
    }
    
    if (!fundingRounds.length) {
      return null
    }
    let fundingRound = fundingRounds[0]
    for (let i = 1; i < fundingRounds.length; i++) {
      const currentFundingRound = fundingRounds[i]
      if (new Date(fundingRound.date).getTime() < new Date(currentFundingRound.date).getTime()) {
        fundingRound = currentFundingRound
      }
    }
    return fundingRound
  }

  const latestFundingRound = getLatestFundingRound(company.fundingRounds)
  const [data, setData] = useState<any>(null)
  
  
  useEffect(() => {
    const configureLabels = () => {
      if (latestFundingRound) {
        const { price } = latestFundingRound
        const { hypotheticalSharePrice } = offer
        const _price = hypotheticalSharePrice > 0 ? hypotheticalSharePrice : price
        const labelValues = [0, _price, 2 * _price, 3 * _price, 4 * _price]
        const labels = labelValues.map((label) => { return label.toString() })
        configureData(labelValues, labels)
      }
    }

    const calculateDistribution = (price: number, totalShares: number, exerciseCost: number, desiredLiquidity: number) => {
      const clientDistribution = (price * totalShares * (1 - pp)) - lp < 0 ? desiredLiquidity : (price * totalShares * (1 - pp)) - lp + desiredLiquidity
      const esoDistribution = totalShares * price < lp ? totalShares * price - (desiredLiquidity + exerciseCost) : totalShares * pp * price + lp - (desiredLiquidity + exerciseCost)
      const fallbackEsoDistribution = totalShares * price
      return { 
        eso: fallbackEsoDistribution > esoDistribution ? esoDistribution : fallbackEsoDistribution, 
        client: clientDistribution > 0 ? clientDistribution : 0
      }
    }
    
    const configureData = (labelValues: number[], labels: string[]) => {
      const { equities } = offer
      const totalShares = equities.reduce((partialSum, equity) => partialSum + equity.amount, 0)
      const clientDataValues = labelValues.map((label) => { return calculateDistribution(label, totalShares, offer.exerciseCost ?? 0, offer.desiredLiquidity).client })
      const esoDataValues = labelValues.map((label) => { return calculateDistribution(label, totalShares, offer.exerciseCost ?? 0, offer.desiredLiquidity).eso })
      const _data = {
        labels,
        datasets: [
        {
          label: 'You',
          data: clientDataValues,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'ESO',
          data: esoDataValues,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ]}
      setData(_data)
    }
    configureLabels()
  }, [pp, lp, latestFundingRound, offer])


  return (
    <Stack>
      {
        data && (
          <Box borderRadius={10} bg="white" p={isMobile ? (0):(5)}>
            <Line options={options} data={data} />
          </Box>
        )
      }
    </Stack>
  )
}