import { Magic } from "magic-sdk"
import { useState } from "react"
import { FaUserAlt } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import {
  Box,
  Button,
  chakra,
  Heading,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Stack,
  Text,
  useToast,
  Center,
} from "@chakra-ui/react"
import { HEADER_GET_STARTED } from "./constants"
import { useAuthContext, setSessionCookie } from "../../hooks"
import { UserType } from "../../types"

interface Props {
  isInitialSignUp: boolean
}

export const LoginPage = ({ isInitialSignUp }: Props) => {
  const navigate = useNavigate()
  const { setDidToken } = useAuthContext()
  const toast = useToast()
  const magic = new Magic(process.env.REACT_APP_MAGIC_API_KEY ?? "")
  const CFaUserAlt = chakra(FaUserAlt)

  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const handleKeyUp = (key: string) => {
    if (key === "Enter") {
      handleLoginClick()
    }
  }

  const handleLoginClick = async () => {
    setIsLoading(true)
    try {
      const didToken = await magic.auth.loginWithMagicLink({
        email,
        // redirectURI: new URL('/offer', window.location.origin).href, // optional redirect back to your app after magic link is clicked
      });
  
      if (!didToken) {
        setIsLoading(false)
        return
      }
    
      // Validate didToken with server
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + didToken,
        },
        credentials: "include"
      })
      
      if (res.status === 200) {
        const resJson = await res.json()
        const { user } = resJson
        if (user) {
          setSessionCookie(user, didToken)
          const { type } = user
          if (type === UserType.CLIENT) {
            if (isInitialSignUp) {
              navigate(`/intro`)
            } else {
              navigate(`/client`)
            }
          } else {
            navigate(`/dashboard`)
          }
        } else {
          throw Error("User does not exist")
        }
      } else {
        setIsLoading(false)
        throw Error(`Failed to fetch with status: ${res.status}`)
      }
    } catch(e) {
      console.log(e)
      toast({
        title: `Oops something went wrong while logging in. Please contact your equity partner in the meantime.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      })
      setIsLoading(false)
    }
  }

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="brand.300"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Heading color="white">Welcome</Heading>
        {
          isInitialSignUp && (
            <Text color="white" fontSize={14} pt={10} w="40%" textAlign="center">
              { HEADER_GET_STARTED }
            </Text>
          )
        }
        <Box minW={{ base: "90%", md: "468px" }}>
          <Stack
            borderRadius={5}
            spacing={5}
            p={10}
            mt={10}
            backgroundColor="darkBlue.300"
            boxShadow="md"
          >
            <FormControl>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<CFaUserAlt color="white" />}
                />
                <Input
                  color="white"
                  type="email"
                  placeholder="Email address"
                  value={email}
                  onKeyUp={(event) => handleKeyUp(event.key)}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </InputGroup>
            </FormControl>
            <Button
              borderRadius={5}
              variant="solid"
              bg="darkBlue.100"
              color="white"
              width="full"
              isLoading={isLoading}
              onClick={handleLoginClick}
            >
              Login
            </Button>
          </Stack>
        </Box>
        <Center pt={10}>
          <Text color="white" fontSize={14} w="70%" textAlign="center">
            By clicking the Login button, you agree to our {' '}
              <Link color="white" onClick={() => navigate("/terms")}>
                Terms of Service
              </Link> and {' '}
              <Link color="white" onClick={() => navigate("/privacy-policy")}>
                Privacy Policy
              </Link>.
          </Text>
        </Center>
      </Stack>
    </Flex>
  )
}