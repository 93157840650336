import {
  Text,
  chakra,
} from "@chakra-ui/react"

export const Title = chakra((props) => {
  return (
    <Text
      {...props}
      color="black"
      fontSize="3xl"
      fontWeight="bold"
    />
  )
})