import {
  HStack,
  Select,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react"
import { DollarInput } from "../../Main/ui"
import { STATES } from "../../../constants"
import { format } from "../../../utils"
import { useDevice } from "../../../hooks"

interface Props {
  income: number
  fmv: number
  handleIncomeChange: (value: number) => any
  handleStateChange: (value: string) => any
  handleMarriedStatusChange: (value: string) => any
  handleCurrentFmvChange: (value: number) => any
}

export const TaxDetailsComponent = ({
  income,
  fmv,
  handleIncomeChange,
  handleStateChange,
  handleMarriedStatusChange,
  handleCurrentFmvChange
}: Props) => {
  const { isMobile } = useDevice()
  return (
    <>
    {
      isMobile ? (
        <Stack p={2}>
          <Text fontSize="lg" fontWeight="bold">Additional Tax Info</Text>
          <Text fontSize="sm" color="gray.500" pb={2}>Fill out the information below for the tax year of your exercise</Text>
          <Stack spacing={0} pl={5} pr={5} pb={0}>
            <Stack borderRadius={10} bg="white" p={5} pb={0}>
              <Text>Current Income</Text>
              <DollarInput value={format(String(income.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')))} setValue={(value) => handleIncomeChange(parseFloat(value))} />
            </Stack>
            <Stack borderRadius={10} bg="white" p={5} pb={0}>
              <Text>State</Text>
              <Select onChange={(event) => handleStateChange(event.target.value)}>
                {
                  STATES.map((state) => {
                    return (
                      <option key={state} value={state}>{state}</option>
                    )
                  })
                }
              </Select>
            </Stack>
            <Stack borderRadius={10} bg="white" p={5} pb={0}>
              <Text>Married?</Text>
              <Select onChange={(event) => handleMarriedStatusChange(event.target.value)}>
                <option value={"No"}>No</option>
                <option value={"Yes"}>Yes</option>
              </Select>
            </Stack>
            <Stack borderRadius={10} bg="white" p={5} pb={0}>
              <Text>Current FMV</Text>
              <DollarInput value={format(String(fmv))} setValue={(value) => handleCurrentFmvChange(parseFloat(value))} />
            </Stack>
            <Spacer />
          </Stack>
        </Stack>
      ) : (
        <Stack>
          <Text fontSize="lg" fontWeight="bold">Additional Tax Info</Text>
          <Text fontSize="sm" color="gray.500" pb={10}>Fill out the information below for the tax year of your exercise</Text>
          <HStack spacing={3}>
            <Spacer />
            <Stack borderRadius={10} bg="white" p={5}>
              <Text>Current Income</Text>
              <DollarInput value={format(String(income.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')))} setValue={(value) => handleIncomeChange(parseFloat(value))} />
            </Stack>
            <Stack borderRadius={10} bg="white" p={5}>
              <Text>State</Text>
              <Select onChange={(event) => handleStateChange(event.target.value)}>
                {
                  STATES.map((state) => {
                    return (
                      <option key={state} value={state}>{state}</option>
                    )
                  })
                }
              </Select>
            </Stack>
            <Stack borderRadius={10} bg="white" p={5}>
              <Text>Married?</Text>
              <Select onChange={(event) => handleMarriedStatusChange(event.target.value)}>
                <option value={"No"}>No</option>
                <option value={"Yes"}>Yes</option>
              </Select>
            </Stack>
            <Stack borderRadius={10} bg="white" p={5}>
              <Text>Current FMV</Text>
              <DollarInput value={format(String(fmv))} setValue={(value) => handleCurrentFmvChange(parseFloat(value))} />
            </Stack>
            <Spacer />
          </HStack>
        </Stack>
      )
    }
    </>
    
  )
}