import { Avatar, Box, Button, Divider, HStack, Spacer, Stack, Text, VStack } from "@chakra-ui/react"
import { DealManager } from "../../../types"
import { openInNewTab } from "../../../utils"

interface Props {
  dealManager: DealManager | null
}

export const NoDealsComponent = ({ dealManager }: Props) => {
  return (
    <>
      {
        dealManager ? (
          <Box
            borderRadius={10}
            border="1px"
            borderColor="gray.300"
          >
            <Stack pt={10} pb={10} pl={5} pr={5}>
              <Text pb={5} fontSize="xl">
                Get started by scheduling a call with your equity partner
              </Text>
              <HStack>
                <Button bg="brand.100" color="white" onClick={() => openInNewTab(dealManager.calendly)}>
                  Schedule a call
                </Button>
                <Spacer />
              </HStack>
            </Stack>
            <Divider />
            <Text
              fontSize={16}
              color="gray.600"
              pl={5}
              pt={5}
              pr={5}
            >
              Equity Partner:
            </Text>
            <HStack p={5}>
              <Avatar name={`${dealManager.firstName} ${dealManager.lastName}`} w={20} h={20} src={dealManager.profilePicture} />
              <Stack>
                <Text
                  fontSize={16}
                  color="gray.600"
                  pl={5}
                >
                  { `${dealManager.firstName} ${dealManager.lastName}` }
                </Text>
                <Text
                  fontSize={16}
                  color="gray.600"
                  pl={5}
                >
                  { dealManager.phoneNumber }
                </Text>
                <Text
                  fontSize={16}
                  color="gray.600"
                  pl={5}
                >
                  { dealManager.email }
                </Text>
              </Stack>
            </HStack>
          </Box>
        ) : (
          <VStack>
            <Text pt={10} pb={5} fontSize="3xl" fontWeight="extrabold">
              Welcome to the ESO Portal
            </Text>
            <Text pb={10} fontSize="xl">
              Get started by scheduling a call with your equity partner
            </Text>
          </VStack>
        )
      }
    </>
  )
}
