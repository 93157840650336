import {
  Box,
  Divider,
  Flex,
  HStack,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { ExerciseCostChart, OptionsInfoComponent, TaxDetailsComponent } from "./components"
import { getFederalTax } from "./helpers"
import { TaxResultBox } from "./ui"
import { STATE_ABBREVIATIONS_MAP } from "../../constants"
import { StateTaxData, StockOptionType, TaxCalculation, TaxData } from "../../types"
import { useDevice } from "../../hooks"

export const TaxCalculationComponent = () => {
  const { isMobile } = useDevice()

  const [taxCalculations, setTaxCalculations] = useState<TaxCalculation[]>([{ amount: 1, strikePrice: 0.01, type: StockOptionType.ISO }])
  const [fmv, setFmv] = useState<number>(0.02)
  const [income, setIncome] = useState<number>(50000)
  const [currentState, setCurrentState] = useState("Alabama")
  const [marriedStatus, setMarriedStatus] = useState("")
  const [amt, setAmt] = useState(0)
  const [federalTax, setFederalTax] = useState(getFederalTax(false, 50000 - 12400))
  const [exerciseCost, setExerciseCost] = useState(0.01)
  const [isoSpread, setIsoSpread] = useState(0)
  const [nsoTax, setNsoTax] = useState(0)
  const [taxData, setTaxData] = useState<TaxData[]>([])
  const [stateTaxData, setStateTaxData] = useState<StateTaxData[]>([])

  useEffect(() => {
    const fetchTaxData = async () => {
      const res = await fetch(`https://api.esofund.io/tax/2022`)
      const data = await res.json()
      if (data.success) {
        setTaxData(data.taxData)
      }
    }

    const fetchStateTaxData = async () => {
      const res = await fetch(`https://api.esofund.io/tax/state/2022`)
      const data = await res.json()
      if (data.success) {
        setStateTaxData(data.taxData)
      }
    }

    fetchTaxData()
    fetchStateTaxData()
  }, [])

  useEffect(() => {
    const getSpread = (type: StockOptionType) => {
      let spread = 0  
      for (const taxCalculation of taxCalculations) {
        if (taxCalculation.type.toLowerCase() === type) {
          spread += taxCalculation.amount * (fmv - taxCalculation.strikePrice)
        }
      }
      return spread
    }

    const isMarried = marriedStatus === "Yes"

    const isoSpread = getSpread(StockOptionType.ISO)
    const nsoSpread = getSpread(StockOptionType.NSO)
    const exerciseCost = taxCalculations.reduce((a, b) => a + b.amount * b.strikePrice, 0)

    setExerciseCost(exerciseCost)

    const exemption = taxData.find(t => isMarried ? t.marriageStatus === "married" : t.marriageStatus === "single")?.exemption ?? 0
    const amtThreshold = taxData.find(t => isMarried ? t.marriageStatus === "married" : t.marriageStatus === "single")?.amtThreshold ?? 0
    const fedDeduction = taxData.find(t => isMarried ? t.marriageStatus === "married" : t.marriageStatus === "single")?.fedDeduction ?? 0
    const fedAmtLevel = taxData.find(t => isMarried ? t.marriageStatus === "married" : t.marriageStatus === "single")?.fedAmtLevel ?? 0

    const fedTaxableIncome = income + nsoSpread - fedDeduction
    const fedTaxableIncomeOnly = income - fedDeduction
    const amtIncome = income + isoSpread + nsoSpread
    const _amtExemption = exemption - 0.25 * (amtIncome - amtThreshold) > 0 ? exemption - 0.25 * (amtIncome - amtThreshold) : 0 
    const amtExemption = amtIncome > amtThreshold ? _amtExemption : exemption
    const fedAmtRate = amtIncome - amtExemption > fedAmtLevel ? 0.28 : 0.26

    const taxDataSorted = taxData.filter(t => (isMarried && t.marriageStatus === "married") || (!isMarried && t.marriageStatus === "single")).sort((a, b) => b.fedLevel - a.fedLevel)
    let fedTax = 0
    let fedIncomeOnlyTax = 0
    let isFedTaxSet = false
    let isFedTaxOnlySet = false

    for (let i = 0; i < taxDataSorted.length; i++) {
      const tax = taxDataSorted[i]
      if (fedTaxableIncome > tax.fedLevel && !isFedTaxSet) {
        fedTax = tax.fedOwe + tax.fedRate * (fedTaxableIncome - tax.fedLevel) + (tax.socSec + tax.medicare) * fedTaxableIncome
        setFederalTax(fedTax)
        isFedTaxSet = true
      }

      if (fedTaxableIncomeOnly > tax.fedLevel && !isFedTaxOnlySet) {
        fedIncomeOnlyTax = tax.fedOwe + tax.fedRate * (fedTaxableIncomeOnly - tax.fedLevel) + (tax.socSec + tax.medicare) * fedTaxableIncomeOnly
        isFedTaxOnlySet = true
      }

      if (isFedTaxSet && isFedTaxOnlySet) {
        break
      }
    }

    const stateAbbrev = STATE_ABBREVIATIONS_MAP[currentState as keyof typeof STATE_ABBREVIATIONS_MAP]
    const filteredStateTaxData = stateTaxData.filter(t => t.state === stateAbbrev)
    const stateTaxDataSorted = filteredStateTaxData.sort((a, b) => b.stateLevel - a.stateLevel)
    const stateDeduction = filteredStateTaxData.find(t => isMarried ? t.marriageStatus === "married" || t.marriageStatus === "na" : t.marriageStatus === "single" || t.marriageStatus === "na")?.stateDeduction ?? 0
    const stateTaxibleIncome = income + nsoSpread - stateDeduction
    const stateTaxableIncomeOnly = income - stateDeduction

    let stateTax = 0
    let stateAmtRate = 0
    let stateIncomeOnlyTax = 0
    let isStateTaxSet = false
    let isStateTaxOnlySet = false

    for (let i = 0; i < stateTaxDataSorted.length; i++) {
      const tax = stateTaxDataSorted[i]
      if (stateTaxibleIncome > tax.stateLevel) {
        stateTax = tax.stateOwe + tax.stateRate * (stateTaxibleIncome - tax.stateLevel)
        // stateIncomeOnlyTax = tax.stateOwe + tax.stateRate * (stateTaxableIncomeOnly - tax.stateLevel)
        stateAmtRate = tax.stateAmtRate
        isStateTaxSet = true
      }

      if (stateTaxableIncomeOnly > tax.stateLevel) {
        stateIncomeOnlyTax = tax.stateOwe + tax.stateRate * (stateTaxableIncomeOnly - tax.stateLevel)
        isStateTaxOnlySet = true
      }

      if (isStateTaxSet && isStateTaxOnlySet) {
        break
      }
    }

    const totalAmt = amtIncome > amtExemption ? (amtIncome - amtExemption) * (fedAmtRate + stateAmtRate) : 0
    const amt = totalAmt > fedTax ? totalAmt - fedTax : 0 
    setAmt(amt)

    const incomeOnlyTax = fedIncomeOnlyTax + stateIncomeOnlyTax
    const nsoTax = stateTax + fedTax - incomeOnlyTax > 0 ? stateTax + fedTax - incomeOnlyTax : 0 
    setNsoTax(nsoTax)
    setIsoSpread(isoSpread)

  }, [taxCalculations, fmv, income, currentState, marriedStatus, stateTaxData, taxData])

  return (
    <>
    {
      isMobile ? (
        <Stack spacing={3} p={5} w="100%" borderTopRadius={10}>
          <Text fontSize="3xl" fontWeight="bold">
            Exercise Tax Calculator
          </Text>
          <OptionsInfoComponent taxCalculations={taxCalculations} setTaxCalculations={setTaxCalculations} />
          <Divider />
          <TaxDetailsComponent
            income={income}
            fmv={fmv}
            handleIncomeChange={(value) => setIncome(value)}
            handleStateChange={(value) => setCurrentState(value)}
            handleCurrentFmvChange={(value) => setFmv(value)}
            handleMarriedStatusChange={(value) => setMarriedStatus(value)}
          />
          <Divider />
          <Stack>
            <Spacer />
            <Flex align={'end'}>
              <TaxResultBox label={"Federal Tax"} value={`$${federalTax.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`} />
              <Spacer />
              <TaxResultBox label={"Estimated AMT Tax"} value={`$${amt.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`} />
              <Spacer />
              <TaxResultBox label={"ISO Spread"} value={`$${isoSpread.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`} />
            </Flex>
            <Box p={0} m={0}>
              <ExerciseCostChart exerciseCost={exerciseCost} amt={amt} nsoTax={nsoTax} />
            </Box>
            <Spacer />
          </Stack>
        </Stack>
      ) : (
        <Stack spacing={10} p={10} w="100%" borderTopRadius={10}>
          <Text fontSize="3xl" fontWeight="bold">
            Exercise Tax Calculator
          </Text>
          <OptionsInfoComponent taxCalculations={taxCalculations} setTaxCalculations={setTaxCalculations} />
          <Divider />
          <TaxDetailsComponent
            income={income}
            fmv={fmv}
            handleIncomeChange={(value) => setIncome(value)}
            handleStateChange={(value) => setCurrentState(value)}
            handleCurrentFmvChange={(value) => setFmv(value)}
            handleMarriedStatusChange={(value) => setMarriedStatus(value)}
          />
          <Divider />
          <HStack>
            <Spacer />
            <Stack w="30%">
              <TaxResultBox label={"Federal Tax"} value={`$${federalTax.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`} />
              <TaxResultBox label={"Estimated AMT Tax"} value={`$${amt.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`} />
              <TaxResultBox label={"ISO Spread"} value={`$${isoSpread.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`} />
            </Stack>
            <Box w="60%" pl={5}>
              <ExerciseCostChart exerciseCost={exerciseCost} amt={amt} nsoTax={nsoTax} />
            </Box>
            <Spacer />
          </HStack>
        </Stack>
      )
    }
    
    </>
  )
}
// Add Tax Primer PDF, CPA Referral PDF only when Offer is sent and NDA is signed.