import {
  Button,
  chakra,
} from "@chakra-ui/react"

export const NegativeButton = chakra((props) => {
  return (
    <Button
      {...props}
      variant="outline"
      borderColor="black"
      color="black"
    />
  )
})
