export const getFederalTax = (isMarried: boolean, taxibleIncome: number) => {
  if (isMarried) {
    if (taxibleIncome > 622050) {
      return 167307.5 + 0.37 * (taxibleIncome - 622050)
    } else if (taxibleIncome > 414700) {
      return 94735 + 0.35 * (taxibleIncome - 414700)
    } else if (taxibleIncome > 326600) {
      return 66543 + 0.32 * (taxibleIncome - 326600)
    } else if (taxibleIncome > 171050) {
      return 29211 + 0.24 * (taxibleIncome - 171050)
    } else if (taxibleIncome > 80250) {
      return 9235 + 0.22 * (taxibleIncome - 80250)
    } else if (taxibleIncome > 19750) {
      return 1975 + 0.12 * (taxibleIncome - 19750)
    } else {
      return 0.1 * taxibleIncome
    }
  } else {
    if (taxibleIncome > 518400) {
      return 156235 + 0.37 * (taxibleIncome - 518400)
    } else if (taxibleIncome > 207350) {
      return 47367.5 + 0.35 * (taxibleIncome - 207350)
    } else if (taxibleIncome > 163300) {
      return 33271.5 + 0.32 * (taxibleIncome - 163300)
    } else if (taxibleIncome > 85525) {
      return 14605.5 + 0.24 * (taxibleIncome - 85525)
    } else if (taxibleIncome > 40125) {
      return 4617.5 + 0.22 * (taxibleIncome - 40125)
    } else if (taxibleIncome > 9876) {
      return 987.5 + 0.12 * (taxibleIncome - 9875)
    } else {
      return 0.1 * taxibleIncome
    }
  }
}