import { Grid, Text, VStack } from "@chakra-ui/react"
import { Resource } from "../../types"
import { ResourceGridItem } from "./ui"
import { useDevice } from "../../hooks"

interface Props {
  resources: Resource[]
}

export const ResourcesComponent = ({ resources }: Props) => {
  const { isMobile } = useDevice()
  return (
    <>
      {
        isMobile ? (
          <>
            <VStack>
              <Text pt={5} fontSize={20} fontWeight="bold">
                Helpful Resources
              </Text>
              <Grid templateColumns='repeat(2, 1fr)' gap={2} p={5}>
                {
                  resources.map((resource, index) => {
                    return (
                      <ResourceGridItem key={index} resource={resource} />
                    )
                  })
                }
              </Grid>
            </VStack>
          </>
        ) : (
          <VStack>
            <Text pt={10} fontSize={20} fontWeight="bold">
              Helpful Resources
            </Text>
            <Grid templateColumns='repeat(3, 1fr)' gap={6} p={10}>
              {
                resources.map((resource, index) => {
                  return (
                    <ResourceGridItem key={index} resource={resource} />
                  )
                })
              }
            </Grid>
          </VStack>
        )
      }
    </>
  )
}
