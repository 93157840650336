import { useState } from "react"
import { HStack, Input, InputGroup } from '@chakra-ui/react'
import { Button } from "@chakra-ui/react"

interface Props {
  searchDeals: (searchText: string) => void
}

export const EquityPartnerDashboardSearchBar = ({ searchDeals }: Props) => {
  const [searchText, setSearchText] = useState<string>("")

  const handleSearchDeals = () => {
    searchDeals(searchText)
  }

  return (
    <HStack pl={10} pt={10} w="70%">
      <InputGroup w="100%" bg="white">
        <Input
          borderColor="black"
          color="black"
          placeholder="Search for Client or Company"
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          onKeyUp={event => {
            if (event.key === 'Enter'){
              searchDeals(searchText)
            }
          }
        }
        />
      </InputGroup>
      <Button bg="brand.300" color="white" onClick={handleSearchDeals}>
        Search
      </Button>
    </HStack>
  )
}
