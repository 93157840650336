import axios from "axios"
import { useEffect, useState } from "react"
import './App.css'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { Main } from './components/Main'
import { getUser, getSessionCookie, setSessionCookie, UserContext } from './hooks'
import { Me } from "./types"

const theme = extendTheme({
  colors: {
    brand: {
      25: "#5856e7",
      50: "#5856e7",
      100: "#324C82",
      300: "#2563EA",
      500: "#5856e7",
      600: "#5856e7",
      900: "#C6D0E3",
    },
    darkBlue: {
      100: "#0D1C46",
      300: "#3D496B"
    },
    white: {
      25: "white",
      50: "white",
      100: "white",
      500: "white",
      600: "white",
    },
  },
  styles: {
    global: () => ({
      body: {
        fontFamily: "Lato",
        overflow: "hidden"
      }
    })
  }
})

function App() {
  const [user, setUser] = useState<Me | null>(getSessionCookie())
  
  useEffect(() => {
    const fetchMe = async () => {
      const _user = await getUser()
      if (_user) {
        setUser(_user)
      }
    }

    if (!user) {
      fetchMe()
    }
  }, [user])

  return (
    <UserContext.Provider value={user}>
      <ChakraProvider theme={theme}>
        <Main />
      </ChakraProvider>
    </UserContext.Provider>
  )
}

export default App
