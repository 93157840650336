import {
  Chart as ChartJS,
  registerables,
} from "chart.js"
import { useEffect, useState } from "react"
import { Bar } from "react-chartjs-2"
import { Box } from "@chakra-ui/react"

ChartJS.register(
  ...registerables
)

export const options = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false
    }
  }
}

interface Props {
  exerciseCost: number
  amt: number
  nsoTax: number
}

export const ExerciseCostChart = ({ exerciseCost, amt, nsoTax }: { exerciseCost: number, amt: number, nsoTax: number }) => {
  const [data, setData] = useState<any>(null)

  useEffect(() => {
    const data = {
      labels: ['Exercise Cost', 'AMT', 'NSO Tax'],
      datasets: [{
        label: '',
        data: [exerciseCost, amt, nsoTax],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)'
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)'
        ],
        borderWidth: 1
      }]
    }
    setData(data)
  }, [exerciseCost, amt, nsoTax])

  return (
    <Box>
      {
        data && (
          <Box borderRadius={10} bg="white" p={5}>
            <Bar options={options} data={data} />
          </Box>
        )
      }
    </Box>
  )
}