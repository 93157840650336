import { OrderedList, List, ListItem, Stack, Text } from "@chakra-ui/react"

export const PrivacyPolicyPage = () => {
  return (
    <Stack p={20} h="100vh" overflowY="scroll">
      <Text fontSize="2xl" fontWeight="bold">
        Privacy Policy
      </Text>
      <Text fontSize="md" pb={10}>
        Last Updated - May 2023
      </Text>
      <Text pb={10} fontSize="md">
        This Privacy Policy is incorporated by reference into the ESO Management Services, LLC and its affiliated entities (collectively, the “Company” or “we” and “us”) Terms and Conditions. The Privacy Policy explains how the Company may collect, use, and disclose information we obtain through the "Service" as defined in the Terms and Conditions.
      </Text>
      <Text pb={5} fontSize="md" fontWeight="bold">
        OVERVIEW AND UPDATES
      </Text>
      <Text pb={10} fontSize="md">
        We respect your privacy and are committed to safeguarding your Personal Information and communicating transparently regarding our privacy practices.
      </Text>
      <Text pb={10} fontSize="md">
        We reserve the right to update this Privacy Policy to reflect changes in and to keep you informed about our privacy practices. If we make any changes, we will notify you by contacting you through the Service, this Policy, via a separate electronic communication, or by some combination of these methods. If you continue to use the Service after the Privacy Policy has been updated, this continued use will indicate your acceptance of the Privacy Policy as modified.
      </Text>
      <Text pb={10} fontSize="md">
        By using the Service, you are authorizing us to collect, process, store, share, and anonymize your Personal Information as outlined in this Privacy Policy. If you do not accept the provisions of this Privacy Policy, you are not authorized to use the Service and must discontinue use immediately
      </Text>
      <Text pb={5} fontSize="md" fontWeight="bold">
        CONTACT INFORMATION.
      </Text>
      <Text pb={10} fontSize="md">
        If you have any questions, comments, or concerns about your Personal Information, this Privacy Policy, your privacy, or any of our privacy practices, please contact us by email at privacy@esofund.com or by mailing correspondence to:
      </Text>
      <Text pl={10} fontSize="md">
        Attn: Data Protection
      </Text>
      <Text pl={10} fontSize="md">
        ESO Management Services, LLC
      </Text>
      <Text pl={10} fontSize="md">
        635 Mariners Island Boulevard, Suite 204,
      </Text>
      <Text pb={10} pl={10} fontSize="md">
        San Mateo, CA 94404
      </Text>
      <Text pb={5} fontSize="md" fontWeight="bold">
        PERSONAL INFORMATION WE COLLECT ABOUT YOU.
      </Text>
      <Text fontSize="md" pb={10}>
        When you inquire about the Service, register to use the Service, use the Service, and/or communicate with us about the Service or otherwise, we may collect information about you (“Personal Information”). This Personal Information may include the following:
      </Text>
      <List pl={10} pr={10} pb={10}>
        <ListItem pb={5}>
          Name;
        </ListItem>
        <ListItem pb={5}>
          Email address;
        </ListItem>
        <ListItem pb={5}>
          Address;
        </ListItem>
        <ListItem pb={5}>
          Phone number;
        </ListItem>
        <ListItem pb={5}>
          Government issued IDs and/or documents;
        </ListItem>
        <ListItem pb={5}>
          Employment information;
        </ListItem>
        <ListItem pb={5}>
          Bank account information; and
        </ListItem>
        <ListItem pb={5}>
          Stock option-related legal documents.
        </ListItem>
      </List>
      <Text pb={10} fontSize="md">
        Depending on how you use the Service, the information we collect may differ. For example, if you are a broker or investor, we may collect your name, contact information, information about your place of work, and so forth, but not your financial information.
      </Text>
      <Text pb={10} fontSize="md">
        We may receive your Personal Information directly from you as you interact with the Service, or various Personal Information may be collected automatically, from third parties or by other means. In the case of third parties, we may receive Personal Information from our Service Providers (defined below) who provide aspects of the Service functionality and other services for us.
      </Text>
      <Text pb={10} as="i" fontSize="md">
        Special Category or Sensitive Personal Data
      </Text>
      <Text pb={10} fontSize="md">
        As the Service is related to the purchase of stock options, we receive and process certain financial information related to such transactions, which is enumerated above.
      </Text>
      <Text pb={10} as="i" fontSize="md">
        Support-related Information
      </Text>
      <Text pb={10} fontSize="md">
        We may collect Personal Information from you through your communications with our customer-support team as you seek assistance with the Service. The nature and content of that Personal Information may be specific to the issue being resolved and may or may not be enumerated above.
      </Text>
      <Text pb={10} as="i" fontSize="md">
        Automatically Collected Information and Cookies
      </Text>
      <Text pb={10} fontSize="md">
        When you use the Service, we may automatically collect usage-related information such as your IP address or other device addresses or IDs, web browser and/or device type, the web pages or sites visited just before or just after using the Service, the pages or other content the you view or interact with on the Service, and the dates and times of the visit, access, or use of the Service. We also may use these technologies to collect information regarding your interaction with email messages, such as whether you opened, clicked on, or forwarded a message.
      </Text>
      <Text pb={10} fontSize="md">
        Additionally, we may automatically collect certain information from your device by using various types of technologies, including cookies, “clear gifs" or “web beacons”, or other tracking technologies. Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies that may be used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service. You can obtain more information about cookies by visiting http://www.allaboutcookies.org.
      </Text>
      <Text pb={10} fontSize="md">
        We use cookies to make our Service functional, help us improve it, and to provide you with information about it. For example, we may use cookies and similar technologies to: (i) personalize our Service, such as remember your information so that you do not have to re-enter it during subsequent visits; (ii) provide customized content and information; (iii) monitor and analyze the effectiveness of Service and third-party marketing activities; (iv) monitor aggregate site usage metrics such as total number of visitors and pages viewed; and (v) track your entries, submissions, and status.
      </Text>
      <Text pb={10} fontSize="md">
        Most browsers allow you to block and delete cookies. However, if you disable essential and functional cookies, the Service may be limited or not work properly.
      </Text>
      <Text pb={10} as="i" fontSize="md">
        Targeted Advertising
      </Text>
      <Text pb={10} fontSize="md">
        Some third parties may use Personal Information to provide you with interest-based (behavioral) advertising or other targeted content. We do not control these third parties' tracking technologies or how they may be used. If you have any questions about any targeted content, you should contact the responsible provider directly. For information about how you can opt out of receiving targeted advertising from us, see the OPTING OUT OF ELECTORNIC COMMUNICATIONS section below.
      </Text>
      <Text pb={5} fontSize="md" fontWeight="bold">
        HOW WE USE YOUR PERSONAL INFORMATION.
      </Text>
      <Text pb={10} fontSize="md">
        We use your Personal Information to facilitate and improve our Service; secure our Service; validate the information you provide; and communicate with you. For example, we may use your Personal Information as follows:
      </Text>
      <OrderedList p={10}>
        <ListItem pb={5}>
          To provide and maintain the Service.
        </ListItem>
        <ListItem pb={5}>
          To communicate with you regarding the Service.
        </ListItem>
        <ListItem pb={5}>
          To inform you of new features and offerings.
        </ListItem>
        <ListItem pb={5}>
          To personalize the Service to you.
        </ListItem>
        <ListItem pb={5}>
          To provide you with support.
        </ListItem>
        <ListItem pb={5}>
          To conduct research and development.
        </ListItem>
        <ListItem pb={5}>
          To monitor site usage.
        </ListItem>
        <ListItem pb={5}>
          To secure the Service.
        </ListItem>
        <ListItem pb={5}>
          To detect, address, debug, and prevent technical issues.
        </ListItem>
        <ListItem pb={5}>
          To generate anonymized information that we share with third parties.
        </ListItem>
        <ListItem pb={5}>
          To enforce our legal rights and/or meet our legal obligations.
        </ListItem>
      </OrderedList>
      <Text pb={10} as="i" fontSize="md">
        Anonymized Information
      </Text>
      <Text pb={10} fontSize="md">
        We may anonymize and aggregate data (“Anonymized Data”) collected through the Service, have sole ownership rights to it, and may use it for any purpose without restriction. For example, without limitation, we may use Personal Information to generate anonymized data reflecting our holdings in companies, which we may share with third parties, such as investors and brokers.
      </Text>
      <Text pb={5} fontSize="md" fontWeight="bold">
        WE MAY DISCLOSE YOUR INFORMATION.
      </Text>
      <Text pb={10} fontSize="md">
        We may disclose your Personal Information to third parties under the following scenarios: 
      </Text>

      <OrderedList p={10}>
        <ListItem pb={5}>
          To receive services from our Service Providers as outlined below.
        </ListItem>
        <ListItem pb={5}>
          For fraud and misconduct prevention.
        </ListItem>
        <ListItem pb={5}>
          To address a data incident.
        </ListItem>
        <ListItem pb={5}>
          To comply with our legal obligations, law enforcement requests and legal processes, court orders, subpoenas, and so forth.
        </ListItem>
        <ListItem pb={5}>
          To protect and defend our rights, property, or safety.
        </ListItem>
        <ListItem pb={5}>
          With affiliates and subsidiaries.
        </ListItem>
        <ListItem pb={5}>
          As permitted by law.
        </ListItem>
        <ListItem pb={5}>
          In conjunction with a sale or merger as outlined below.
        </ListItem>
        <ListItem pb={5}>
          To respond to your request.
        </ListItem>
        <ListItem pb={5}>
          With your permission.
        </ListItem>
      </OrderedList>

      <Text pb={10} as="i" fontSize="md">
        Service Providers
      </Text>
      <Text pb={10} fontSize="md">
        Our Service is built and provisioned, at least in part, using various different third-party components, infrastructure, or tools provided by third parties (“Service Providers”). These Service Providers, depending on their role, may facilitate the provision of the Service, collect and process data, perform Service-related services, or assist us in analyzing how our Service is used. In performing these services, they may receive and process your Personal Information. You authorize us to disclose your Personal Information to these Service Providers.
      </Text>
      <Text pb={10} fontSize="md">
        We may share any information we receive with Service Providers retained in connection with the provision of the Service. We may allow access to other data collected by the Service to enable the delivery of online advertising on or off this Service or to otherwise facilitate transmittal of information we think may be useful or relevant to you.
      </Text>
      <Text pb={10} fontSize="md">
        The follow is a non-exclusive list of Service Providers that we may use.
      </Text>
      <OrderedList p={10}>
        <ListItem pb={5}>
          GOOGLE ANALYTICS - We use Google Analytics, an independent web analytics service provided by Google, for analytics and measurement to understand how our Service is used. For example, we analyze data about your visits to our sites to do things like optimize product design. You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. For more information on Google Analytics, please visit https://policies.google.com/technologies/partner-sites.  
        </ListItem>
        <ListItem pb={5}>
          HUBSPOT. Hubspot may be used to track and analyze traffic on the Service. You can learn about Hubspot’s privacy practices at https://legal.hubspot.com/privacy-policy.  For information on how you may opt out of tracking by Hubspot, please visit https://knowledge.hubspot.com/reports/customize-your-cookie-tracking-settings-and-privacy-policy-alert. 
        </ListItem>
        <ListItem pb={5}>
          ALEXA METRICS.  Alexa Metrics may be used track and analyze traffic on the Service.  You can learn more about Alexa’s privacy practices at https://www.alexa.com. 
        </ListItem>
        <ListItem pb={5}>
          QUORA. Quora Pixel may be used to track traffic and conversions. You can learn more about Quora’s privacy practices at https://quoraadsupport.zendesk.com/hc/en-us/categories/115001573928-Pixels-Tracking. 
        </ListItem>
        <ListItem pb={5}>
          STATCOUNTER. Statcounter may be used to track and analyze traffic on the Service. You can learn more about Statcounter’s privacy practices at https://statcounter.com.
        </ListItem>
        <ListItem pb={5}>
          LINKEDIN INSIGHTS. Linkedin Insights may be used to track, analyze, and understand visitors to our Service.  You can learn more about LinkedIn’s privacy practices at https://www.linkedin.com/insights.
        </ListItem>
        <ListItem pb={5}>
          FACEBOOK.  Facebook’s business tools may be used to better understand traffic on the Service. You can learn more about Facebook’s privacy practices at https://www.facebook.com/business. 
        </ListItem>
        <ListItem pb={5}>
          GOOGLE TAG MANAGER. Google Tag Manager may be used to manage website tags. You can learn more about Google’s privacy practices for Google Tag Manager at https://marketingplatform.google.com/about/tag-manager.
        </ListItem>
        <ListItem pb={5}>
          GOOGLE ADWORDS. Google Adwords may be used to drive sales, leads, and/or site traffic. You can learn more about Google’s privacy practices for Google Adwords at https://ads.google.com.
        </ListItem>
      </OrderedList>
      <Text pb={10} fontSize="md">
        Please note that the Service may contain features or links to web sites and services provided by third parties. Any information you provide on third-party sites or services is provided directly to the operators of such services and is subject to those operators’ policies, if any, governing privacy and security, even if accessed through the Service. We are not responsible for the content or privacy and security practices and policies of third-party sites or services to which links or access are provided through the Service. We encourage you to learn about third parties’ privacy and security policies before providing them with information.
      </Text>
      <Text pb={10} as="i" fontSize="md">
        Change of Control
      </Text>
      <Text pb={10} fontSize="md">
        Please note that if we are involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership, sale of our assets, or transition of service to another provider, your information may be sold or transferred as part of such a transaction as permitted by law and/or contract. We cannot control how such entities may use or disclose such information. 
      </Text>
      <Text pb={10} as="i" fontSize="md" fontWeight="bold">
        WE DO NOT SELL YOUR PERSONAL INFORMATION.
      </Text>
      <Text pb={10} fontSize="md">
        We do not rent, sell, or share Personal Information about you with other people or nonaffiliated companies for their direct marketing purposes.
      </Text>
      <Text pb={10} as="i" fontSize="md" fontWeight="bold">
        INFORMATION SECURITY.
      </Text>
      <Text pb={10} fontSize="md">
        We take steps to ensure that your information is treated securely and in accordance with this Privacy Policy. Unfortunately, the Internet is not 100% secure, and we cannot ensure or warrant the security of any information you provide to us. We do not accept liability for unintentional disclosure. By using the Service or providing Personal Information to us, you agree that we may communicate with you electronically regarding security, privacy, and administrative issues relating to your use of the Service.
      </Text>
      <Text pb={10} as="i" fontSize="md" fontWeight="bold">
        YOUR RIGHTS UNDER GDPR
      </Text>
      <Text pb={10} fontSize="md">
        Depending on where you reside, you may have rights over your Personal Information that are afforded to you under applicable data protection laws. In particular, if you reside in Europe or the UK, Europe's and the UK's General Data Protection Act (“GDPR”), provide you with the following rights:
      </Text>
      <List p={10}>
        <ListItem pb={5}>
          The right to be informed about the collection and use of your Personal Information, which this Privacy Policy provides.
        </ListItem>
        <ListItem pb={5}>
          The right to access your Personal Information (called a “subject access request” under the GDPR), which you can do by sending an email to us using the contact information provided above. 
        </ListItem>
        <ListItem pb={5}>
          The right to have any data that is inaccurate or incomplete rectified by correcting or completing it.
        </ListItem>
        <ListItem pb={5}>
          The right to erasure (a.k.a., “be forgotten”) by sending an email to us using the contact information provided above and requesting that we delete your Personal Information. 
        </ListItem>
        <ListItem pb={5}>
          The right to restrict the processing of your Personal Information under certain circumstances as provided by Article 18 of the GDPR.
        </ListItem>
        <ListItem pb={5}>
          The right to obtain and reuse a copy of your Personal Information for your own purposes.
        </ListItem>
        <ListItem pb={5}>
          The right to object to processing of your Personal Information under certain circumstances provided by Article 21 of the GDPR.
        </ListItem>
      </List>

      <Text pb={10} as="i" fontSize="md">
        How to Exercise Your Privacy Rights
      </Text>
      <Text pb={10} fontSize="md">
        Contact us using the contact information provided above to exercise any of your privacy rights. We will respond to each such request within 30 days after receipt or less.
      </Text>
      <Text pb={10} as="i" fontSize="md">
        Formalities to Exercise Your Privacy Rights
      </Text>
      <Text pb={10} fontSize="md">
        Please keep in mind that in the case of a vague request we may engage you in a dialogue to better understand the motivation and content of the request. We may also refuse manifestly unfounded and excessive (repetitive) requests. We may also require you to prove your identity in some cases to ensure that no rights of third parties are violated by your request.
      </Text>
      <Text pb={10} as="i" fontSize="md">
        Legal Basis for Processing your Personal Information
      </Text>
      <Text pb={10} fontSize="md">
        The legal bases under which we process your Personal Information include your consent, our legitimate interests, to satisfy our contractual obligations, or to satisfy our legal obligations. 
      </Text>
      <Text pb={10} as="i" fontSize="md">
        Other Items
      </Text>
      <Text pb={10} fontSize="md">
        Notification requirements. We commit to notify you, when it is needed under the law, within a reasonable period of time and your data protection authority within the timeframe specified in applicable law about data breaches related to your Personal Information.
      </Text>
      <Text pb={10} fontSize="md">
        Data Protection Authorities. Subject to applicable laws, you may have the right to lodge a complaint with your local data protection authority about any of our activities that you deem are not compliant with applicable law.
      </Text>

      <Text pb={10} as="i" fontSize="md" fontWeight="bold">
        YOUR RIGHTS UNDER THE CCPA.
      </Text>
      <Text pb={10} fontSize="md">
        If you are a California resident, then under the California Consumer Privacy Act (“CCPA’’), you have certain rights regarding the Personal Information that we may have collected about you. The following sections disclose the “what,” “how,” and “why” behind any Personal Information we collect about you, who we share it with, and what your rights are over your Personal Information.
      </Text>
      <Text pb={10} fontSize="md">
        Similar to the GDPR, the consumer rights you may have under the CCPA include the right to have us:
      </Text>
      <List p={10}>
        <ListItem pb={5}>
          Delete any Personal Information collected or maintained by us;
        </ListItem>
        <ListItem pb={5}>
          Correct any inaccurate Personal Information;
        </ListItem>
        <ListItem pb={5}>
          Disclose what Personal Information is being collected and provide access to that Personal Information;
        </ListItem>
        <ListItem pb={5}>
          Disclose to you the Personal Information that we have sold to or shared with third parties;
        </ListItem>
        <ListItem pb={5}>
          Direct us not to sell or share your Personal Information to/with third parties (i.e., opt out of sale or sharing); and
        </ListItem>
        <ListItem pb={5}>
          Not discriminate against you for exercising your privacy rights.
        </ListItem>
      </List>

      <Text pb={10} fontSize="md">
        The basis for the collection of Personal Information and the manner in which Personal Information is used is outlined elsewhere in this Privacy Policy.
      </Text>

      <Text pb={10} fontSize="md" fontWeight="bold">
        DO NOT TRACK POLICY.
      </Text>
      <Text pb={10} fontSize="md">
        California law requires that operators of websites and online services disclose how they respond to a Do Not Track signal. Some browsers have incorporated “Do Not Track” features. Most of these features, when turned on, send a signal or preference to the website or online service that a user visits, indicating that the user does not wish to be tracked. Because there is not yet a common understanding of how to interpret Do Not Track signals, we do not currently respond to Do Not Track signal. We continue to work with the online industry to define a common understanding of how to treat Do Not Track signals.
      </Text>
      <Text pb={10} fontSize="md" fontWeight="bold">
        INTERNATIONAL DATA TRANSFERS.
      </Text>
      <Text pb={10} fontSize="md">
        By using the Service, you will transfer data to the United States. If you are visiting from the European Union or other regions with laws governing data collection and use, please note that you are agreeing to the transfer of your information to the United States and processing globally. By providing your information you consent to any transfer and processing in accordance with this Privacy Policy.
      </Text>
      <Text pb={10} fontSize="md" fontWeight="bold">
        OPTING OUT OF ELECTORNIC COMMUNICATIONS
      </Text>
      <Text pb={10} fontSize="md">
        If you receive electronic communications from us, such as email or text messages, you may unsubscribe at any time by following the instructions contained within the communication or by sending an email to the address provided in the CONTACT INFORMATION section.
      </Text>
      <Text pb={10} fontSize="md">
        Please be aware that if you opt-out of receiving communications from us or otherwise modify the nature or frequency of communications you receive from us, it may take up to ten (10) business days for us to process your request. Additionally, even after you opt-out from receiving communications from us, you will continue to receive essential administrative email communications from us regarding the Service.
      </Text>
      <Text pb={10} fontSize="md">
        If you do not want us to share your Personal Information with third parties for promotional purposes, please send your request to privacy@esofund.com and we will attempt to accommodate it. Please note that some third parties collect information automatically from you when you visit the Service, and you may be required to contact them directly to disable the sharing or use the tools provided by them to opt-out of such sharing.
      </Text>
      <Text pb={10} fontSize="md" fontWeight="bold">
        DATA RETENTION
      </Text>
      <Text pb={10} fontSize="md">
        We only retain the Personal Information collected from you for as long as your account is active, for as long as we need it to fulfill the purposes for which we have initially collected it, or for as long as needed to protect our rights, whichever is longer, unless otherwise required by law. We will retain and use information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
      </Text>
      <Text pb={10} fontSize="md" fontWeight="bold">
        SETTINGS
      </Text>
      <Text pb={10} fontSize="md">
        Although we may allow you to adjust your privacy settings to limit access to certain Personal Information, please be aware that no security measures are perfect or impenetrable. We are not responsible for circumvention of any privacy settings or security measures on the Service. Additionally, we cannot control the actions of other users with whom you may choose to share your information. Further, even after information posted on the Service is removed, caching and archiving services may have saved that information, and other users or third parties may have copied or stored the information available on the Service. We cannot and do not guarantee that information you post on or transmit to the Service will not be viewed by unauthorized persons.
      </Text>
      <Text pb={10} fontSize="md" fontWeight="bold">
        CHILD PRIVACY
      </Text>
      <Text pb={10} fontSize="md">
        We are committed to protecting child privacy. The Service is not intended for children, and we do not intentionally or knowingly collect information, personal or otherwise, about individuals under the age of 18. If you are aware of anyone under 18 using the Service, please contact us using the contact information provided above and we will take required steps to delete any such information and/or prevent that individual from accessing the Service.
      </Text>
      <Text pb={10} fontSize="md" fontWeight="bold">
        CHANGES TO OUR PRIVACY POLICY AND PRACTICES.
      </Text>
      <Text pb={10} fontSize="md">
        We may revise this Privacy Policy, so review it periodically. We will post any adjustments to the Privacy Policy on this web page, and the revised version will be effective when it is posted.
      </Text>
    </Stack>
  )
}