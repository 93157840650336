import { Button, Stack, Text } from "@chakra-ui/react"
import { OfferStatusBar } from "./ui"
import { DealManager, OfferStatus } from "../../types"
import { openInNewTab } from "../../utils"

interface Props {
  dealManager: DealManager
}

export const NoDealComponent = ({ dealManager }: Props) => {
  return (
    <Stack>
      <Text pb={10} fontSize="xl">
        Get started by scheduling a call with your equity partner
      </Text>
      <Button
        colorScheme="brand"
        variant="solid"
        onClick={() => openInNewTab(dealManager.calendly)}
      >
        Schedule a call
      </Button>
    </Stack>
  )
}