import { Box, GridItem, Image, Text } from "@chakra-ui/react"
import { Resource } from "../../../types"
import { openInNewTab } from "../../../utils"
import { useDevice } from "../../../hooks"

interface Props {
  resource: Resource
}

export const ResourceGridItem = ({ resource }: Props) => {
  const {isMobile} = useDevice()
  return (
    <>
    {
      isMobile ? (
        <GridItem
        cursor="pointer"
        bg="white"
        p={2}
        onClick={() => openInNewTab(resource.url)}
        >
          <Box w="100%">
            <Image src={resource.photo ?? "https://images.unsplash.com/photo-1432821596592-e2c18b78144f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8YmxvZ3xlbnwwfHwwfHw%3D&w=1000&q=80"} />
          </Box>
          <Text p={2} fontWeight="bold">
            { resource.title }
          </Text>
      </GridItem>
      ) : (
        <GridItem
        cursor="pointer"
        bg="white"
        onClick={() => openInNewTab(resource.url)}
        >
          <Box w="100%">
            <Image src={resource.photo ?? "https://images.unsplash.com/photo-1432821596592-e2c18b78144f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8YmxvZ3xlbnwwfHwwfHw%3D&w=1000&q=80"} />
          </Box>
          <Text p={5} fontWeight="bold">
            { resource.title }
          </Text>
        </GridItem>
      )
    }
    </>
  )
}
