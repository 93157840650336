import axios from "axios"
import { useEffect, useState } from "react"
import { FaRegCalendarAlt } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { Box, Button, HStack, Spacer, Stack, VStack } from "@chakra-ui/react"
import { P, Title } from "../ui"
import { getUser, getIsLoggedIn } from "../../hooks"
import { Client } from "../../types"
import { openInNewTab } from "../../utils"

export const IntroPage = () => {
  const navigate = useNavigate()
  const [client, setClient] = useState<Client | null>(null)

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const me = await getUser()
        const { didToken } = me
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/client/`, {}, { headers: { Authorization: `Bearer ${didToken}` } })
        const { data } = response
        if (response.status === 200 && data?.success) {
          const _client: Client = data.client
          setClient(_client)
        } else {
          navigate(`/login`)
        }
      } catch(e) {
        console.log(e)
        navigate(`/login`)
      }
    }
    
    const isLoggedIn = getIsLoggedIn()
    if (isLoggedIn) {
      fetchClient()
    } else {
      navigate(`/login`)
    }
  }, [])

  return (
    <VStack p={10} w="100%" h="100vh" overflowY="scroll">
      <Stack w="70%">
        <Title>
          { `${client?.firstName} ${client?.lastName}` }
        </Title>
        <Title>
          Welcome to the ESO Fund Portal
        </Title>
        <P pt={5}>
          {`If you have not scheduled an Intro Call with ${client?.dealManager?.firstName} ${client?.dealManager?.lastName}, we encourage you to do so at your earliest convenience`}
        </P>
        {
          client?.dealManager?.calendly && (
            <Button
              leftIcon={<FaRegCalendarAlt />}
              bg="brand.300"
              color="white"
              variant="solid"
              w="fit-content"
              onClick={() => openInNewTab(client!.dealManager!.calendly)}
            >
              Schedule an Intro Call
            </Button>
          )
        }
        <P pt={5}>
          Let's confirm a few things before we get started processing your Company equity.
        </P>
        <P fontWeight="bold" pt={5}>
          Make sure your name and email are correct:
        </P>
        <HStack spacing={3}>
          <P>
            Name:
          </P>
          <P>
            { `${client?.firstName} ${client?.lastName}` }
          </P>
        </HStack>
        <HStack spacing={3} pb={10}>
          <P>
            Email:
          </P>
          <P>
            { client?.email }
          </P>
        </HStack>
        {/* <Box pt={5} pb={5}>
          <P pb={5}>
            Before you submit your equity information, please sign our mutual NDA. Click the button below and you will receive an email with the NDA attached.
          </P>
          <Button w="fit-content" bg="brand.300" color="white">
            Request NDA
          </Button>
        </Box> */}
        <Spacer />
        <Button w="fit-content" colorScheme="green" onClick={() => navigate("/client")}>
          Continue to ESO Portal
        </Button>
      </Stack>
    </VStack>
  )
}