import { Box, Divider, Stack, Text } from "@chakra-ui/react"
import {
  ID_MUTUAL_NDA,
  ID_409A_PROOF,
  ID_OPTIONS_VESTING_PROOF,
  ID_BC,
  ID_OPTION_PLAN,
  ID_OPTION_GRANT,
  ID_STOCK_CERTIFICATE,
  ID_DRIVERS_LICENSE,
  INSTRUCTIONS_ID,
  INSTRUCTIONS_NDA,
  INSTRUCTIONS_BC,
  INSTRUCTIONS_STOCK_CERTIFICATE,
  INSTRUCTIONS_STOCK_OPTION_PLAN,
  INSTRUCTIONS_OPTION_GRANT_DOCUMENTS,
  STAGE_ONE,
  STAGE_TWO,
  STAGE_THREE,
  STAGE_FOUR,
  ID_UTILITY_BILL,
} from "../constants"
import { DocumentTypeDict } from "../../../constants"
import { Deal, DealDocument, UploadDocumentRow } from "../../../types"
import { RequestFormRow, UploadFilesRow } from "."

interface Props {
  deal: Deal
  documents: DealDocument[  ]
  addDocument: (key: string) => void
  requestForDocument: (type: string) => void
}

const IntroInstructions = ({ deal, documents, addDocument, requestForDocument }: Props) => {
  const REQUIRED_OFFER_DOCS: UploadDocumentRow[] = 
  [
    {
      id: ID_409A_PROOF,
      type: DocumentTypeDict.fmvProofDocument,
      label: "409A Proof",
      description: "Equity portal screenshot or company email trail confirming the current fair market value. **IMPORTANT**: Please make sure the **company name** and **current date** appear in any screenshot.",
    },
    {
      id: ID_OPTIONS_VESTING_PROOF,
      type: DocumentTypeDict.optionsVestingProofDocument,
      label: "Stock Options (or Shares) Vesting Proof",
      description: "Equity portal screenshot or grant documents confirming number of options, grant date, and strike price.",
    },
  ]

  return (
    <Stack>
      <RequestFormRow
        id={ID_MUTUAL_NDA}
        name="Sign Our Mutual NDA"
        instructions={INSTRUCTIONS_NDA}
        requestForDocument={requestForDocument}
      />
      {
        REQUIRED_OFFER_DOCS.map((doc, index) => (
          <UploadFilesRow
            key={index}
            id={doc.id}
            deal={deal}
            name={doc.label}
            instructions={doc.description}
            isUploaded={documents.some((d) => d.type === doc.type)}
            addDocument={addDocument}
          />
        ))
      }
    </Stack>
  )
}

const OfferInstructions = ({ deal, documents, addDocument, requestForDocument }: Props) => {
  const REQUIRED_DOCS = 
  [
    {
      id: ID_OPTION_PLAN,
      type: DocumentTypeDict.optionPlanDocument,
      label: "Stock Option Plan",
      instructions: INSTRUCTIONS_STOCK_OPTION_PLAN,
    },
    {
      id: ID_OPTION_GRANT,
      type: DocumentTypeDict.optionGrantDocument,
      label: "Stock Option Grant Document (for each grant)",
      instructions: INSTRUCTIONS_OPTION_GRANT_DOCUMENTS,
    },
    {
      id: ID_STOCK_CERTIFICATE,
      type: DocumentTypeDict.stockCertificateDocument,
      label: "Stock Certificate Proof (Shares Only)",
      description: "Screenshot of your stock certificate",
      instructions: INSTRUCTIONS_STOCK_CERTIFICATE,
    },
  ]

  return (
    <Stack>
      <RequestFormRow
        id={ID_BC}
        name="Sign our Background Check Permission Form"
        instructions={INSTRUCTIONS_BC}
        requestForDocument={requestForDocument}
      />
      {
        REQUIRED_DOCS.map((doc, index) => (
          <UploadFilesRow
            key={index}
            id={doc.id}
            deal={deal}
            name={doc.label}
            instructions={doc.instructions}
            isUploaded={documents.some((d) => d.type === doc.type)}
            addDocument={addDocument}
          />
        ))
      }
    </Stack>
  )
}

const ContractInstructions = ({ deal, documents, addDocument }: Props) => {
  return (
    <Stack>
      <UploadFilesRow
        id={ID_DRIVERS_LICENSE}
        key={ID_DRIVERS_LICENSE}
        deal={deal}
        name="Upload 2 Forms of ID (Driver's License & Passport)"
        instructions={INSTRUCTIONS_ID}
        isUploaded={documents.some((d) => d.type === DocumentTypeDict.driversLicenseDocument)}
        addDocument={addDocument}
      />
      <UploadFilesRow
        id={ID_UTILITY_BILL}
        key={ID_UTILITY_BILL}
        deal={deal}
        name="(Optional) Please submit a Utility bill"
        instructions={"Please submit a utility bill to verify your address. This is optional but will help expedite the process."}
        isUploaded={documents.some((d) => d.type === DocumentTypeDict.utilityBillDocument)}
        addDocument={addDocument}
      />
      <Text fontSize="lg" pb={5}>
        { `Schedule your closing meeting with ${deal.dealManager.firstName} ${deal.dealManager.lastName}` }
      </Text>
      <Divider />
      <Text fontSize="lg" pt={5} pb={5}>
        Sign Contract on DocuSign
      </Text>
      <Divider />
      <Text fontSize="lg" pt={5} pb={5}>
        Sign and Notarize LPOA in your email
      </Text>
      <Divider />
    </Stack>
  )
}

export const ToDoBox = ({ deal, documents, addDocument, requestForDocument }: Props) => {
  const getWhatsNextInstructions = () => {
    const { stage } = deal
    if (STAGE_ONE.includes(stage)) {
      return (
        <IntroInstructions deal={deal} documents={documents} addDocument={addDocument} requestForDocument={requestForDocument} />
      )
    } else if (STAGE_TWO.includes(stage)) {
      return (
        <OfferInstructions deal={deal} documents={documents} addDocument={addDocument} requestForDocument={requestForDocument} />
      )
    } else if (STAGE_THREE.includes(stage)) {
      return (
        <ContractInstructions deal={deal} documents={documents} addDocument={addDocument} requestForDocument={requestForDocument} />
      )
    } else if (STAGE_FOUR.includes(stage)) {
      return (
        <Box>
          <Text fontWeight="extrabold" fontSize="2xl" color="black">
            { `Congratulations on becoming a shareholder in ${deal.company.name}!` }
          </Text>
          <Text fontSize="lg" color="black" pt={10}>
            If you're considering selling your shares before an IPO, connect with your Equity Partner. We have strong partnerships with secondary marketplaces and can actively list your shares to help you secure early liquidity.
          </Text>
        </Box>
      )
    }
  }

  return (
    <Stack p={10} border="1px solid" m={10} borderRadius={5}>
      {
        !STAGE_FOUR.includes(deal.stage) && (
          <Text fontWeight="extrabold" fontSize="2xl" color="black" pb={5}>
            To-Do
          </Text>
        )
      }
      {
        getWhatsNextInstructions()
      }
    </Stack>
  )
}